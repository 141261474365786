import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNormalizedDataByYearByMetric } from '../../selectors';
import { Button } from 'react-foundation';
import { useFoundation } from '../../utils';
import SingleIndicatorVisual from './visualizations/SingleIndicatorVisual';
import { showIndicatorProfile } from '../../actions';
import IndicatorVsNation from './visualizations/IndicatorVsNation';
import { MetricTypes, getDisplayValue, isValidNormalizedValue } from '../../bizUtils';
import { constants } from '../../utils';

export default function IndicatorCard({
  metric,
  isAvailableInSelectedYear,
  selectedYear
}) {
  useFoundation();
  const dispatch = useDispatch();
  const handleClickViewIndicator = (metric) => {
    dispatch(showIndicatorProfile(metric.id));
  }
  const normalizedDataByStateAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));

  return (
    <React.Fragment>
      {
        isAvailableInSelectedYear
          ?
            (
              <React.Fragment>
                <div className='indicator-card_background-overlay'></div>
                <div className='indicator-card' onClick={() => handleClickViewIndicator(metric)}>
                  <div>
                    <div className='indicator-card_title'>
                      {metric.displayName}
                    </div>
                    <div
                      className='indicator-card_description'
                      dangerouslySetInnerHTML={{__html: metric ? metric.text.short_description : null}}
                    />
                    <div className='indicator-card_visualization is-mobile'>
                      {getTextForMetric(metric, normalizedDataByStateAndMetric, selectedYear)}
                    </div>
                    <Button
                      className='clear indicator-card_view-indicator-button'
                      onClick={() => handleClickViewIndicator(metric)}
                    >
                      VIEW INDICATOR
                    </Button>
                  </div>
                  <div className='indicator-card_visualization is-desktop'>
                    <SingleIndicatorVisual metric={metric} />
                  </div>
                </div>
              </React.Fragment>
            )
          : (
              <div className='indicator-not-available-card' onClick={() => handleClickViewIndicator(metric)}>
                  <div className='indicator-card_title'>
                    {metric.displayName}
                  </div>
                  <div className='indicator-card_description'>
                    {
                      metric.name === 'Residual Vote Rate'
                      ? 'N/A. Residual Vote Rate is not calculated for midterm elections.'
                      : constants.new2020MetricNames.includes(metric.name)
                      ? constants.metricAddedIn2020Text
                      : 'N/A. This indicator is not available for this year.'
                    }
                  </div>
              </div>
            )
      }
    </React.Fragment>
  );
}

const getTextForMetric = (
	metric,
	normalizedDataByStateAndMetric,
	selectedYear,
) => {
  const normalizedDataForAllStates = normalizedDataByStateAndMetric[selectedYear][metric.name];
  const validValuesForAllStates = Object.values(normalizedDataForAllStates).filter(v => isValidNormalizedValue(v));
	if (metric.id === 'OLT') {
		const dataSet = normalizedDataByStateAndMetric[selectedYear][metric.name];
		const allValues = Object.values(dataSet).filter(v => isValidNormalizedValue(v));
		const average = allValues.length ? allValues.reduce((sum, v) => sum + v) / allValues.length : 0;
		const averageDisplayValue = getDisplayValue(null, average, null, true);
		return (
			<div className='indicator-card_relative-to-average'>
				The average state offers {averageDisplayValue} of possible look-up tools
			</div>
		);
	}
	switch (metric.type) {
    case MetricTypes.Enum:
      // this logic is specific to AOR, the only enum type currently. Can expand this if more enum types are added
      if (metric.id !== 'AOR') return null;
      return (
        <div className='indicator-card_relative-to-average'>
          {
            Object.keys(metric.values).map((value) => {
              const countAcrossStates = validValuesForAllStates
                .filter(v => v.toString() === value).length;
              if (value === '1')
                return (
                  <div key={value}>
                    {countAcrossStates} out of 51 states have {metric.displayName}
                  </div>
                );
              if (value === '0.5' && countAcrossStates > 0)
                return (
                  <div key={value}>
                    {countAcrossStates} out of 51 states allow {metric.values[value]}
                  </div>
                );
              return null;
            })
          }
        </div>
      );
    case MetricTypes.Boolean:
      const countAcrossStates = validValuesForAllStates.filter(v => v === 1).length;
      return (
        <div className='indicator-card_relative-to-average'>
          {countAcrossStates} out of 51 states have {metric.displayName}
        </div>
      );
    case MetricTypes.Numeric:
      return (
        <React.Fragment>
          <div className='indicator-card_visualization_title'>NATIONWIDE AVERAGE</div>
          <IndicatorVsNation year={selectedYear} metric={metric} />
        </React.Fragment>
      )
    default:
      return null;
	}
}
