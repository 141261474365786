import React, { useState, useEffect } from 'react';
import NavFilterBar from './NavFilterBar';
import { useFoundation } from '../../utils';
import { useSelector}  from 'react-redux';
import RankSection from './visualizations/RankSection';
import RankCompareColumn from './visualizations/RankCompareColumn';
import {
  getNormalizedAverageByStateByYearSelectedMetrics,
  getSelectedYear,
  getSelectedCompareYear,
  getSelectedAvailableMetrics,
  getCompareMetricDeltaWarning,
} from '../../selectors';

export default function Rank() {
  useFoundation();
  const selectedAvailableMetrics = useSelector(state => getSelectedAvailableMetrics(state));
  const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
  const selectedYear = useSelector(state => getSelectedYear(state));
  const selectedCompareYear = useSelector(state => getSelectedCompareYear(state));
  const compareMetricDeltaWarning = useSelector(state => getCompareMetricDeltaWarning(state));
  const averageByStateInSelectedYear = normalizedAverageByStateByYear[selectedYear];
  const [currentHoverState, setCurrentHoverState] = useState(null);

  const handleSetCurrentHoverState = (newHoverState, prevHoverState) => {
    setCurrentHoverState(currentHoverState => {
      if (newHoverState === null) {
        // only set to null if user hasn't already hovered to new state
        if (prevHoverState === currentHoverState) {
          return null;
        } else {
          return currentHoverState;
        }
      }
      return newHoverState;
    })
  };

  useEffect(() => {
    setCurrentHoverState(null);
  }, [selectedYear, selectedCompareYear])

  const sections = [
    { title: '81%', max: 1000, min: .805, titleText: 'and above', isFirst: true },
    { title: '71-80%', max: .805, min: .705 },
    { title: '61-70%', max: .705, min: .605 },
    { title: '51-60%', max: .605, min: .505, },
    { title: '50%', max: .505, min: -1000, titleText: 'and below' },
  ];

  if (selectedAvailableMetrics.length === 0) {
    return (
      <div>
        <div>
          <NavFilterBar />
        </div>
        <div className='rank'>
          <div className='rank_no-metrics-warning'>
            Please select at least one indicator to view state ranks.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <NavFilterBar />
      </div>
      <div className='rank'>
        <div className='rank_description is-mobile'>
          Explore the data by selecting filters and interacting with state ranks.
          {
          selectedAvailableMetrics.length === 1
          ? 
            <React.Fragment>
              <br /><br />
              The indicator percentages in this view are normalized across all states. See raw values in the map view.
            </React.Fragment>
          : null
        }
        <div className='rank_metrics-warning'>
          {compareMetricDeltaWarning}
        </div>
        </div>
        
        <span id='rank-top' data-magellan-target='rank-top'></span>
        {
          selectedCompareYear
          ?
            (
              <div className='rank_compare-wrapper'>
                <RankCompareColumn
                  setCurrentHoverState={handleSetCurrentHoverState}
                  currentHoverState={currentHoverState}
                  year={selectedYear}
                  otherYear={selectedCompareYear}
                />
                <RankCompareColumn
                  setCurrentHoverState={handleSetCurrentHoverState}
                  currentHoverState={currentHoverState}
                  year={selectedCompareYear}
                  otherYear={selectedYear}
                />
              </div>
            )
          :
            (
              sections.map((section, index) => {
                const statesInSection = Object.keys(averageByStateInSelectedYear)
                  .filter(s =>
                    averageByStateInSelectedYear[s] <= section.max &&
                    averageByStateInSelectedYear[s] > section.min
                  );
                return (
                  <RankSection
                    setCurrentHoverState={handleSetCurrentHoverState}
                    statesInSection={statesInSection}
                    key={index}
                    section={section}
                    index={index}
                    currentHoverState={currentHoverState}
                  />
                );
              })
            )
        }
        <span id='rank-bottom' data-magellan-target='rank-bottom'></span>
      </div>
    </div>
  );
}
