import React, { useState } from 'react';
import { useSelector, useDispatch }  from 'react-redux';
import { getStates } from '../../selectors';
import { useFoundation } from '../../utils';
import closeIcon from '../images/modal-close.svg';
import closeHoverIcon from '../images/modal-close-hover.svg';
import { Button } from 'react-foundation';
import { showStateProfile } from '../../actions';
import closeMobileIcon from '../images/modal-close-mobile-black.svg';

export default function SelectAState() {
	useFoundation();
	const [filterText, setFilterText] = useState('');
	const dispatch = useDispatch();
	const states = useSelector(state => getStates(state));
	const handleClickState = (stateAbbv) => {
		dispatch(showStateProfile(stateAbbv));

		// looks jittery if we clear filter immediately, let the modal close first
		setTimeout(() => setFilterText(''), 500);
	}

	// fade in
	window.$('#select-state-modal').on('open.zf.reveal', () => {
		window.$('#select-state-modal').addClass('force-fade').addClass('raised');
		setTimeout(() => window.$('#select-state-modal').removeClass('force-fade'));
		setTimeout(() => window.$('#select-state-modal').removeClass('raised'), 1500);
	});

	const closeModal = () => {
		window.$('#select-state-modal').addClass('force-fade');
		setTimeout(() => {
			window.$('#select-state-modal').removeClass('force-fade');
			window.$('#select-state-modal').foundation('close');
		}, 500);
	}

  return (
		<div
			data-multiple-opened='true'
			className='select-state-modal full reveal'
			id='select-state-modal'
			data-reveal=''
			data-v-offset='0'
			data-h-offset='0'
			data-close-on-esc='false'
			data-animation-in="fade-in"
		>
			<div className='select-state-modal_wrapper'>
				<button className='select-state-modal_close-button close-button' aria-label='Close reveal' type='button' onClick={closeModal}>
					<div className='is-desktop'>
						<span>CLOSE</span>
						<img className='is-not-hovered' alt='close-button' src={closeIcon} />
						<img className='is-hovered' alt='close-button' src={closeHoverIcon} />
					</div>
					<img className='is-mobile' alt='close-button' src={closeMobileIcon} />
				</button>
				<div className='select-state-modal_content'>
					<div className='select-state-modal_title'>
						SELECT A STATE
					</div>
					<input
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
						type='text'
						placeholder='Search for a state'
						className='select-state-modal_search is-mobile'
					/>
					<div className='select-state-modal_states'>
							{
									states.filter(s => s.name.toLowerCase().includes(filterText.toLowerCase())).map((state, index) => (
										<div key={index}>
											<Button
												className='clear'
												onClick={() => handleClickState(state.abbv)}
											>
												{state.name}
											</Button>
										</div>
									))
							}
					</div>
				</div>
			</div>
		</div>
  );
}
