import React from 'react';
import NavFilterBar from './NavFilterBar';
import IndicatorCard from './IndicatorCard';
import { useFoundation } from '../../utils';
import { useSelector } from 'react-redux';
import { getVisibleMetricsGrouped, getSelectedYear } from '../../selectors';

export default function Indicators() {
  useFoundation();
  const metricGroups = useSelector(state => getVisibleMetricsGrouped(state));
  const selectedYear = useSelector(state => getSelectedYear(state));

  return (
    <div>
      <div>
        <NavFilterBar filterByIndicatorDisabled={true} compareYearsDisabled={true} />
      </div>
      <div className='indicators'>
        <div className='indicator_description is-mobile'>
          Explore the data by selecting individual criteria, or indicators.
        </div>
        {
          metricGroups.filter(mg => mg.metrics.length > 0).map(metricGroup => {
            return (
              <div key={metricGroup.id} className='indicators-group'>
                <div className='indicators-group_title'>
                  {metricGroup.name}
                </div>
                <div className='grid-x grid-padding-x'>
                  {
                    metricGroup.metrics.map((metric, index) => (
                      <div key={index} className='cell small-12 medium-6'>
                        <IndicatorCard
                          selectedYear={selectedYear}
                          metric={metric}
                          isAvailableInSelectedYear={metric.yearsAvailable.includes(selectedYear)}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
