import React, { useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import Map from './data/Map';
import Indicators from './data/Indicators';
import Rank from './data/Rank';
import Modals from './Modals';
import Error404 from './Error404';
import ErrorBoundary from './ErrorBoundary';
import ErrorUnhandled from './ErrorUnhandled';
import { fetchData } from '../actions/index';
import 'foundation-sites/dist/css/foundation.min.css';
import './scss//App.scss';
import { Foundation } from 'foundation-sites';
import jquery from 'jquery';

const Loading = () => {
  return (
    <div className='loading'>
      <div className="loadingio-spinner-spin-200ogfyedcs">
        <div className="ldio-wg75lf948f">
          <div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  const dispatch = useDispatch();
  const loadComplete = useSelector(state => state.loadComplete);
  const loadFailed = useSelector(state => state.loadFailed);
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchData());

    // bind jquery to window and foundation-sites js to jquery, for use in components
    window.jQuery = jquery;
    window.$ = jquery;
    Foundation.addToJquery(window.$);
    window.jQuery(document).ready($ => ($(document).foundation()));
    window.Foundation = Foundation;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Header />
      <ErrorBoundary>
        <div className='l-main'>
          <TransitionGroup>
            <CSSTransition
              key={`${location.pathname}-${loadComplete}-${loadFailed}`}
              classNames='fade'
              timeout={{enter: 500, exit: 0}}
            >
              {
                loadComplete
                  ?
                    (
                      <Switch location={location}>
                        <Route exact path='/'><Redirect to='/data/map' /></Route>
                        <Route exact path='/data'><Redirect to='/data/map' /></Route>
                        <Route exact path='/data/map'><Map /></Route>
                        <Route exact path='/data/indicators'><Indicators /></Route>
                        <Route exact path='/data/rank'><Rank /></Route>
                        <Route><Error404 /></Route>
                      </Switch>
                    )
                  : loadFailed
                    ? <ErrorUnhandled />
                    : <Loading />
                }
            </CSSTransition>
          </TransitionGroup>
        </div>
        { loadComplete ?  <Modals /> : null }
      </ErrorBoundary>
      <Footer />
    </React.Fragment>
  );
};
