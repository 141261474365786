import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { constants } from '../../utils';

export default function StateProfileIndicatorNotAvailable({
	metric,
	handleComponentResize,
	componentHeights
}) {
	const onValueResize = (_width, height) => {
		handleComponentResize('value', metric, height);
	};
	const onNameResize = (_width, height) => {
		handleComponentResize('name', metric, height);
	};

	return (
		<div>
			<div
				style={{ minHeight: Math.max(...Object.values(componentHeights.value[metric.groupId])) }}
				className='state-profile-indicator-not-available_title'
			>
				<div>
					<ReactResizeDetector handleHeight onResize={onValueResize} />
					Not captured
				</div>
			</div>
			<div
				style={{ minHeight: Math.max(...Object.values(componentHeights.name[metric.groupId])) }}
				className='state-profile-indicator-not-available_name'
			>
				<div>
					<ReactResizeDetector handleHeight onResize={onNameResize} />
					{metric.displayName}
				</div>
			</div>
			<div className='state-profile-indicator-not-available_description'>
				{
					metric.name === 'Residual Vote Rate'
						? 'Residual Vote Rate is not captured for midterm elections.'
						: constants.new2020MetricNames.includes(metric.name)
						? constants.metricAddedIn2020Text
						: 'This indicator is not available for this year.'
				}
			</div>
		</div>
	)
}
