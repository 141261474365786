import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, selectAll, scaleTime, scaleLinear, extent, line, max} from 'd3';
import { geoAlbersUsa, geoPath } from 'd3-geo';
import {
	getStateShapes,
	getStates,
	getNormalizedAverageByStateByYearSelectedMetrics,
	getAvailableYearsOfSameTypeAsSelected,
	getNormalizedDataByYearByMetric,
	getIfMetricsAreFiltered,
	getSelectedAvailableMetrics,
	getEnricheddDataByYearByMetric,
	getMetrics,
} from '../../../selectors';
import { isValidNormalizedValue, InvalidValues, getColorFromValue } from '../../../bizUtils';
import { showStateProfile, updateHoveredStateAbbv } from '../../../actions';
import { isMobile } from '../../../utils';

const convertCoords = (x,y, yAbove, yBelow, svg, element) => {
  const offset = svg.getBoundingClientRect();
	const matrix = element.getScreenCTM();
  return {
    x: (matrix.a * x) + (matrix.c * y) + matrix.e - offset.left,
		yBottomAbove: window.innerHeight + offset.top - (matrix.b * x) - (matrix.d * yAbove) - matrix.f,
		yTopBelow: (matrix.b * x) + (matrix.d * yBelow) + matrix.f - offset.top,
	};
}

export default function Heatmap({ year, hidden, forCompare, simple, metric }) {
	const dispatch = useDispatch();
	const selectedAvailableMetrics = useSelector(state => getSelectedAvailableMetrics(state));
	const metrics = useSelector(state => getMetrics(state));
	const enrichedDataByStateAndMetric = useSelector(state => getEnricheddDataByYearByMetric(state));
	const areMetricsFiltered = useSelector(state => getIfMetricsAreFiltered(state));
	const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
	const normalizedDataByStateAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));
	const shapes = useSelector(state => getStateShapes(state));
	const states = useSelector(state => getStates(state));
	const averageByState = metric
		? metric.yearsAvailable.includes(year)
			? normalizedDataByStateAndMetric[year][metric.name]
			: states.reduce((grouped, state) => {
				grouped[state.abbv] = InvalidValues.NA;
				return grouped;
			}, {})
		: normalizedAverageByStateByYear[year];
	const availableYearsOfSameTypeAsSelected = useSelector(state => getAvailableYearsOfSameTypeAsSelected(state));

	const getStateColor = useCallback((d) => {
		const stateAverage = averageByState[d.properties.abbv];
		return getColorFromValue(stateAverage);
	}, [averageByState]);

	const getStateOutlineColor = useCallback((d) => {
		const stateAverage = averageByState[d.properties.abbv];
		return stateAverage === InvalidValues.INC ? '#525971' : '#1c1536';
	}, [averageByState]);

	const initializeTooltip = useCallback(() => {
		selectAll('.heatmap-tooltip').remove();
		const tooltip = select('body').selectAll('#root')
			.data([{}].concat(...shapes.features))
			.enter()
			.append('div')
			.attr('class', 'heatmap-tooltip is-desktop')
			.attr('id', (d) => `heatmap-tooltip-${d.properties.abbv}`);
		tooltip.append('div').attr('class', 'heatmap-tooltip_title');
		const tooltipPercentage = tooltip.append('div').attr('class', 'heatmap-tooltip_percentage');
		tooltipPercentage.append('div').attr('class', 'heatmap-tooltip_percentage_value');
		tooltipPercentage.append('div').attr('class', 'heatmap-tooltip_percentage_description');
		const otherYears = tooltip.append('div').attr('class', 'heatmap-tooltip_other-years');
		otherYears.append('div').attr('class', 'heatmap-tooltip_other-years_sparkline');
		otherYears.append('div').attr('class', 'heatmap-tooltip_other-years_values').text('VALUES');
	}, [shapes.features]);

	const handleMouseOut = (mouseOverState, d, tooltip) => {
		if (simple) return;
		if (mouseOverState === d.properties.abbv) {
			// some actions should only be taken if user is leaving map entirely
			// rather than moving between states
			selectAll('path')
				.style('opacity', 1)
				.attr('stroke', d2 => d2.properties ? getStateOutlineColor(d2) : '#000000' )
				.attr('stroke-width', 0.5);
				dispatch(updateHoveredStateAbbv(null));
		}
		if (!forCompare) {
			tooltip
				.style('visibility', 'hidden')
				.style('opacity', '0');
		} else {
			tooltip.select('.heatmap-tooltip_title').text('');
		}
	};

	const handleMouseOver = (stateData, tooltip, stateUnderMouse, year) => {
		if (simple) return;
		dispatch(updateHoveredStateAbbv(stateData.properties.abbv));
		updateMapStatesOnMouseOver(stateUnderMouse);
		updateTooltipOnMouseOver(tooltip, stateUnderMouse, stateData, year);
		return stateData.properties.abbv;
	};

	const updateMapStatesOnMouseOver = (stateUnderMouse) => {
		select('.map_maps').selectAll('path')
		.style('opacity',function (d) {
			if (!d) return 1;
			return this === stateUnderMouse // is this the state  being hovered
				? 1
				// is this the state being hovered on the other map during compare?
				: select(stateUnderMouse).data()[0].properties.abbv === d.properties.abbv
					? 1
					// any other state
					: 0.14;
		})
		.attr('stroke',function (d) {
			if (!d) return null;
			return select(stateUnderMouse).data()[0].properties.abbv === d.properties.abbv 
				? 'white'
				// any other state
				: getStateOutlineColor(d)
		})
		.attr('stroke-width',function (d) {
			if (!d) return null;
			return select(stateUnderMouse).data()[0].properties.abbv === d.properties.abbv
				? 1
				// any other state
				: 0.5;
		});
	};

	const updateTooltipOnMouseOver = (tooltip, stateUnderMouse, stateData, year) => {
		tooltip.select('.heatmap-tooltip_title').text(stateData.properties.name);
		if (forCompare) return;
		setTooltipPosition(stateUnderMouse, tooltip);
		const value = averageByState[stateData.properties.abbv];
		if (isValidNormalizedValue(value)) {
			updateTooltipDetails(tooltip, stateData, value, year);
		} else {
			updateTooltipDetailsForMissingOrNotApplicableData(tooltip, value);
		}
	};

	const setTooltipPosition = (stateUnderMouse, tooltip) => {
		const stateBoundingBox = select(stateUnderMouse).node().getBBox();
		const {x ,yBottomAbove, yTopBelow} = convertCoords(
			stateBoundingBox.x + stateBoundingBox.width/2,
			stateBoundingBox.y,
			stateBoundingBox.y,
			stateBoundingBox.y + stateBoundingBox.height,
			select('body').node(),
			stateUnderMouse
		);

		tooltip
			.style('left', `${x-200}px`)
			.style('visibility', 'visible')
			.style('opacity', 1);

		// position tooltip below states that are higher up in the map, above for the remainder of states
		if (stateBoundingBox.y > 120) {
			tooltip.style('top', null);
			tooltip.style('bottom', `${yBottomAbove + 10}px`);
		}
		else {
			tooltip.style('bottom', null);
			tooltip.style('top', `${yTopBelow + 10}px`)
		}
	};

	const updateTooltipDetails = (tooltip, stateData, value, year) => {
		// we compute rank based on rank of value, not state, in array. so if two states share same value, they have same rank
		const rank = Object.keys(averageByState).indexOf(stateData.properties.abbv) + 1;

		if (selectedAvailableMetrics.length !== 1) {
			tooltip.select('.heatmap-tooltip_percentage_value').text(`${Math.round(value * 100)}%`);
			tooltip.select('.heatmap-tooltip_percentage_description').text(`${areMetricsFiltered ? 'AVERAGE' : 'EPI AVERAGE'} (#${rank})`);
		} else {
			const metric = metrics.find(m => m.name === selectedAvailableMetrics[0]);
			const stateValue = enrichedDataByStateAndMetric[year][metric.name][stateData.properties.abbv];
			tooltip.select('.heatmap-tooltip_percentage_value').text(stateValue.displayValue);
			// tooltip.select('.heatmap-tooltip_percentage_description').text(metric.type === MetricTypes.Numeric && !metric.notPercent ? metric.displayName : '');
			tooltip.select('.heatmap-tooltip_percentage_description').text(`INDICATOR VALUE (#${rank})`);
		}

		// this asumes that we only show tooltips for selected year, not compare year in var availableYearsOfSameTypeAsSelected
		let otherYearHtml = '';
		const sparklineData = [{date: new Date(year, 0, 1), value}]
		availableYearsOfSameTypeAsSelected.forEach(otherYear => {
			const otherYearByState = metric
				? normalizedDataByStateAndMetric[otherYear][metric.name]
				: normalizedAverageByStateByYear[otherYear];
			const otherYearValue = otherYearByState[stateData.properties.abbv];
			if (isValidNormalizedValue(otherYearValue)) {
				sparklineData.push({date: new Date(otherYear, 0, 1), value: otherYearValue});
				const otherYearRank = Object.keys(otherYearByState).indexOf(stateData.properties.abbv) + 1;

				if (selectedAvailableMetrics.length !== 1) {
					otherYearHtml += `${otherYear}: ${Math.round(otherYearValue*100)}% (#${otherYearRank})<br>`;
				} else {
					const metric = metrics.find(m => m.name === selectedAvailableMetrics[0]);
					const stateValue = enrichedDataByStateAndMetric[otherYear][metric.name][stateData.properties.abbv];
					otherYearHtml += `${otherYear}: ${stateValue.displayValue} (#${otherYearRank})<br>`;
				}
			}
		});
		tooltip.select('.heatmap-tooltip_other-years_values').html(otherYearHtml);

		sparklineData.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
		updateTooltipSparkline(tooltip, sparklineData);
	};
	
	const updateTooltipSparkline = (tooltip, sparklineData) => {
		sparklineData.sort((a, b) => a.year > b.year ? -1 : a.year < b.year ? 1 : 0);
		const sparklineContainer = tooltip.select('.heatmap-tooltip_other-years_sparkline');
		const margin = {top: 10, right: 1.5, bottom: 5, left: 1.5},
			width = 44 - margin.left - margin.right,
			height = 30 - margin.top - margin.bottom;

		// append the svg object to the body of the page
		sparklineContainer.selectAll('svg').remove();

		if (sparklineData.length < 2) return;

		const color = sparklineData[sparklineData.length - 1].value >= sparklineData[sparklineData.length - 2].value
			? '#37d463'
			: '#f3485a';
		const sparklineSvg = sparklineContainer
			.append('svg')
				.attr('width', width + margin.left + margin.right)
				.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
		const x = scaleTime()
			.domain(extent(sparklineData, function(d) { return d.date; }))
			.range([ 0, width ]);
		const y = scaleLinear()
			.domain([0, max(sparklineData, function(d) { return +d.value; })])
			.range([ height, 0 ]);
		sparklineSvg.append('path')
			.datum(sparklineData)
			.attr('fill', 'none')
			.attr('stroke', color)
			.attr('stroke-width', 1.5)
			.attr('d', line()
				.x(function(d) { return x(d.date) })
				.y(function(d) { return y(d.value) })
				);
		sparklineSvg.selectAll('.circle')
			.data(sparklineData)
			.enter()
			.append('circle')
			.attr('r', 1.5)
			.attr('cx', function(d) { return x(d.date);  })
			.attr('cy', function(d) { return y(d.value); })
			.style('fill', '#ffffff');
	};

	const updateTooltipDetailsForMissingOrNotApplicableData = (tooltip, value) => {
		tooltip.select('.heatmap-tooltip_percentage_value').text('');
		switch (value) {
			case InvalidValues.INC:
				tooltip.select('.heatmap-tooltip_percentage_description').text('MISSING DATA');
				break;
			case InvalidValues.NA:
			default:
				tooltip.select('.heatmap-tooltip_percentage_description').text('NOT APPLICABLE');	
		}
		tooltip.select('.heatmap-tooltip_other-years_values').html(null);
		tooltip.select('.heatmap-tooltip_other-years_sparkline').text('');
	};

	const d3Container = useRef(null);

	useEffect(() => {
		if (!averageByState) return;

		// build container for map
		const svg = select(d3Container.current);
		svg.selectAll('g').remove();
		const mapGroups = svg.append('g');

		// when not comparing or showing simple, we initialize tooltip within map. When comparing, two maps share tooltips
		// so its initialized outside
		// note that the the tooltip is a shared DOM element. If the map is implemented to show tooltips in more than one
		// place, the tooltip should be given a unique ID per instance instead
		if (!forCompare && !simple) {
			initializeTooltip();
		}

		// build map with various events and populate with data
		const projection = geoAlbersUsa().translate([750/2, 489/2]);
		const path = geoPath().projection(projection);

		let mouseOverState = null;
		mapGroups.selectAll('path')
			.data(shapes.features)
			.enter()
			.append('path')
				.attr('d', d => path(d.geometry))
				.on('mouseover', function(d) {
					if (simple || isMobile()) return;
					const tooltip = forCompare
						? select('.heatmap-compare-tooltip')
						: select(`#heatmap-tooltip-${d.properties.abbv}`);
					mouseOverState = handleMouseOver(d, tooltip, this, year);
				})
				.on('mouseout', function(d) {
					if (simple || isMobile()) return;
					const tooltip = forCompare
						? select('.heatmap-compare-tooltip')
						: select(`#heatmap-tooltip-${d.properties.abbv}`);
					handleMouseOut(mouseOverState, d, tooltip);
				})
				.on('click', function() {
					if (simple || isMobile()) return;
					const d = select(this).data()[0];
					dispatch(showStateProfile(d.properties.abbv))
				});

		// add in the color
		svg.selectAll('path')
			.attr('stroke', d => getStateOutlineColor(d))
			.attr('stroke-width', 0.5)
			.attr('opacity', 1)
			.style('cursor', simple ? 'default' : 'pointer')
			.attr('class', 'maps_map-state')
			.style('fill', d => getStateColor(d));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [averageByState, forCompare]);

	if (!averageByState) return null;
	return (
		<svg
			className={hidden ? 'is-hidden' : null}
			// maintain aspect ratio of 15/23
			viewBox='0 0 750 489'
			preserveAspectRatio='xMidYMid meet'
			ref={d3Container}
		/>
	);
}