import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import {TopBar, TopBarLeft, TopBarRight, Menu, MenuItem} from 'react-foundation';
import logo from './images/logo.svg';

export default function Header() {
  return (
		<React.Fragment>
				<TopBar className='header' id='responsive-top-bar'>
					<TopBarLeft className='header_left'>
						<Link to='/'>
							<div>
								<img alt='logo' src={logo} />
							</div>
							<div className='header_left_title'>
								Elections
								<br />
								Performance Index
							</div>
						</Link>
					</TopBarLeft>
					<TopBarRight className='header_right'>
						<Menu>
							<MenuItem><NavLink activeClassName='is_active' to='/data'>DATA</NavLink></MenuItem>
							<MenuItem><a href={`${process.env.REACT_APP_CONTENT_HOST}/learn`}>LEARN</a></MenuItem>
							<MenuItem><a href={`${process.env.REACT_APP_CONTENT_HOST}/about`}>ABOUT</a></MenuItem>
						</Menu>
					</TopBarRight>
				</TopBar>
			</React.Fragment>
  );
}
