import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	getIndicatorProfile,
	getVisibleMetricsGrouped,
	getSelectedYear,
	getAllAvailableYears,
	getNormalizedDataByYearByMetric,
	getAvailableYearsOfSameTypeAsSelected,
	getEnricheddDataByYearByMetric,
} from '../../selectors';
import { useFoundation } from '../../utils';
import closeIcon from '../images/modal-close.svg';
import closeHoverIcon from '../images/modal-close-hover.svg';
import closeMobileIcon from '../images/modal-close-mobile-black.svg';
import { changeSelectedYear, showIndicatorProfile, closeIndicatorProfile } from '../../actions';
import Heatmap from './visualizations/Heatmap';
import SingleIndicatorVisual from './visualizations/SingleIndicatorVisual';
import {
	isValidNormalizedValue,
	MetricTypes,
	getNationwideAverageDisplayForMetricAndYear
} from '../../bizUtils';
import { select, scaleLinear, scaleTime, max, line, extent } from 'd3';
import twitterIcon from '../images/twitter-grey.svg';
import twitterHoverIcon from '../images/twitter-pink-on-white.svg';
import facebookIcon from '../images/facebook-grey.svg';
import facebookHoverIcon from '../images/facebook-pink-on-white.svg';
import emailIcon from '../images/email-grey.svg';
import emailHoverIcon from '../images/email-pink.svg';
import linkIcon from '../images/link-grey.svg';
import linkHoverIcon from '../images/link-pink.svg';
import nextArrow from '../images/next-arrow.svg';
import nextArrowHover from '../images/next-arrow-grey.svg';
import previousArrow from '../images/previous-arrow.svg';
import previousArrowHover from '../images/previous-arrow-grey.svg';
import previousArrowSmall from '../images/previous-arrow-small.svg';
import { constants } from '../../utils';

export default function IndicatorProfile() {
	useFoundation();
	const dispatch = useDispatch();
	const [metric, setMetric] = useState(null);
	const [shareContent, setShareContent] = useState({});
	const indicatorProfile = useSelector(state => getIndicatorProfile(state));
	const metricsGrouped = useSelector(state => getVisibleMetricsGrouped(state));
	const selectedYear = useSelector(state => getSelectedYear(state));
	const allAvailableYears = useSelector(state => getAllAvailableYears(state));

	const normalizedDataByStateAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));
	const enrichedDataByStateAndMetric = useSelector(state => getEnricheddDataByYearByMetric(state));
	const availableYearsOfSameTypeAsSelected = useSelector(state => getAvailableYearsOfSameTypeAsSelected(state));
	const metrics = metricsGrouped.map(mg => mg.metrics).flat();
	const metricIndex = metric ? metrics.findIndex(m => m.id === metric.id) : 0;
	const previousMetric = metricIndex > 0 ? metrics[metricIndex - 1] : null;
	const nextMetric = metricIndex < metrics.length - 1 ? metrics[metricIndex + 1] : null;
	const [justCopied, setJustCopied] = useState(false);
	const handleCopyToClipboard = () => {
		setJustCopied(true);
		setTimeout(() => setJustCopied(false), 1500);
	}

	const currentUrlEncoded = encodeURIComponent(window.location.href);
	const currentUrl = window.location.href;

	const [nationwideAverageDisplay, setNationwideAverageDisplay] = useState({});
	const [allYearsOfTypeData, setAllYearsOfTypeData] = useState(null);

	const handleSelectedYearChange = (clickedYear) => {
		dispatch(changeSelectedYear(clickedYear));
	};

	const handleClose = () => {
		dispatch(closeIndicatorProfile());
	}

	useEffect(() => {
		if (indicatorProfile.currentMetricId) {
			setMetric(metrics.find(m => m.id === indicatorProfile.currentMetricId));
		}
	}, [metrics, indicatorProfile]);

	useEffect(() => {
		if (!metric || !metric.yearsAvailable.includes(selectedYear)) return;
		setShareContent({
			text: `Check out ${metric.displayName} in the Elections Performance Index, a nonpartisan, objective measure of U.S. election administration.`,
			subject: `Read about ${metric.displayName} in the Elections Performance Index`
		});
		const metricDataForYear = normalizedDataByStateAndMetric[selectedYear][metric.name];
		const getAverageFromArray = (array) => {
			const validValuesArray = array.filter(v => isValidNormalizedValue(v));
			if (!validValuesArray || validValuesArray.length === 0) return 0;
			return validValuesArray.reduce((sum, value) => sum + value) / validValuesArray.length;
		};
		const nationwideAverageLocal = getAverageFromArray(Object.values(metricDataForYear));
		const nationwideAverageDisplay = getNationwideAverageDisplayForMetricAndYear(
			metric,
			normalizedDataByStateAndMetric,
			enrichedDataByStateAndMetric,
			selectedYear
		);
		setNationwideAverageDisplay(nationwideAverageDisplay);
		const allYearsOfTypeDataLocal = [{ 
			date: new Date(selectedYear, 0, 1),
			value: nationwideAverageLocal,
			year: selectedYear,
			display: nationwideAverageDisplay,
		}];
		availableYearsOfSameTypeAsSelected.forEach(otherYear => {
			if (!metric.yearsAvailable.includes(otherYear)) return;
			const otherYearValue = getAverageFromArray(Object.values(normalizedDataByStateAndMetric[otherYear][metric.name]));
			const otherYearDisplay = getNationwideAverageDisplayForMetricAndYear(
				metric,
				normalizedDataByStateAndMetric,
				enrichedDataByStateAndMetric,
				otherYear
			);
			allYearsOfTypeDataLocal.push({ 
				date: new Date(otherYear, 0, 1),
				value: otherYearValue,
				year: otherYear,
				display: otherYearDisplay,
			});
		});
		allYearsOfTypeDataLocal.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
		setAllYearsOfTypeData([...allYearsOfTypeDataLocal]);
		const sparklineContainer = select('.indicator-profile-modal_sparkline');
		const margin = {top: 10, right: 5, bottom: 5, left: 5},
			width = 51 - margin.left - margin.right,
			height = 30 - margin.top - margin.bottom;
			sparklineContainer.selectAll('svg').remove();
			if (allYearsOfTypeDataLocal.length > 1) {
				const color =
					allYearsOfTypeDataLocal[allYearsOfTypeDataLocal.length - 1].value >
					allYearsOfTypeDataLocal[allYearsOfTypeDataLocal.length - 2].value
						? '#37d463'
						: '#f3485a';
				const sparklineSvg = sparklineContainer
					.append('svg')
						.attr('width', width + margin.left + margin.right)
						.attr('height', height + margin.top + margin.bottom)
					.append('g')
					.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
				const x = scaleTime()
					.domain(extent(allYearsOfTypeDataLocal, function(d) { return d.date; }))
					.range([ 0, width ]);
				const y = scaleLinear()
					.domain([0, max(allYearsOfTypeDataLocal, function(d) { return +d.value; })])
					.range([ height, 0 ]);
				sparklineSvg.append('path')
					.datum(allYearsOfTypeDataLocal)
					.attr('fill', 'none')
					.attr('stroke', color)
					.attr('stroke-width', 1.5)
					.attr('d', line()
						.x(function(d) { return x(d.date) })
						.y(function(d) { return y(d.value) })
						);
				sparklineSvg.selectAll('.circle')
					.data(allYearsOfTypeDataLocal)
					.enter()
					.append('circle')
					.attr('r', 1.5)
					.attr('cx', function(d) { return x(d.date);  })
					.attr('cy', function(d) { return y(d.value); })
					.style('fill', '#000000');
			}
	}, [availableYearsOfSameTypeAsSelected, enrichedDataByStateAndMetric, metric, normalizedDataByStateAndMetric, selectedYear]);

  return (
		<div
			data-multiple-opened='true'
			className='indicator-profile-modal full reveal'
			id='indicator-profile-modal'
			data-reveal=''
			data-v-offset='0'
			data-h-offset='0'
			data-close-on-esc='false'
		>
			<div>
				<div className='indicator-profile-modal_wrapper'>
					<button className='indicator-profile-modal_close-button close-button' onClick={handleClose} aria-label='Close reveal' type='button'>
						<div className='is-desktop'>
							<span>CLOSE</span>
							<img className='is-not-hovered' alt='close-button' src={closeIcon} />
							<img className='is-hovered' alt='close-button' src={closeHoverIcon} />
						</div>
						<img className='is-mobile' alt='close-button' src={closeMobileIcon} />
					</button>
					<NavLink className='indicator-profile-modal_select-another is-mobile' exact to='/data/indicators'>
						<img className='is-mobile' alt='previous' src={previousArrowSmall} />
						<span>Select another indicator</span>
					</NavLink>
					<div className='indicator-profile-modal_header'>
						<div className='indicator-profile-modal_title-section'>
							<div className='indicator-profile-modal_title'>
								{metric ? metric.displayName : ''}
							</div>
							<span>in</span>
							<button className='button clear' type='button' data-toggle='indicator-profile-year-selection-dropdown'>
								{selectedYear}
							</button>
							<div
								className='dropdown-pane indicator-profile-modal_select-year-dropdown'
								id='indicator-profile-year-selection-dropdown'
								data-dropdown
								data-hover='true'
								data-hover-pane='true'
								data-hover-delay='0'
								data-alignment='center'
							>
								<div className='indicator-profile-modal_select-year-dropdown-arrow'></div>
								<div className='indicator-profile-modal_select-year-dropdown-contents'>
									{
										allAvailableYears.map(
											year =>
												<button
													onClick={() => handleSelectedYearChange(year)}
													className={selectedYear === year ? 'clear is-selected' : 'clear'}
													key={year}
												>
													{year}
												</button>
											)
									}
								</div>
							</div>
							<div
								className='indicator-profile-modal_title-description'
								dangerouslySetInnerHTML={{__html: metric ? metric.text.short_description : null}}
								/>
							{
								metric && metric.yearsAvailable.includes(selectedYear)
								?
									(
										<React.Fragment>
											<div className='indicator-profile-modal_average'>
												<div>{nationwideAverageDisplay.value}</div>
												<div className='indicator-profile-modal_average-description'>
													{nationwideAverageDisplay.text}
												</div>
											</div>
											<div className='indicator-profile-modal_other-years'>
												<div className='indicator-profile-modal_sparkline'></div>
												<div>
													{ allYearsOfTypeData && 
														[...allYearsOfTypeData]
															.reverse()
															.filter(yearData => yearData.year !== selectedYear)
															.map(yearData =>
																<div key={yearData.year}>
																	{yearData.year}: &nbsp;
																	{
																		metric.type === MetricTypes.Boolean || metric.type === MetricTypes.Enum
																			? `${yearData.display.value} ${yearData.display.text}`
																			: yearData.display.value
																	}
																</div>
															)
													}
												</div>
											</div>
										</React.Fragment>
									)
								: null
							}
							{
								metric && metric.yearsAvailable.includes(selectedYear)
								?
									(
										<div className='indicator-profile-modal_share'>
											SHARE
											<a target='_blank' rel='noreferrer noopener' href={`https://twitter.com/intent/tweet?url=${currentUrlEncoded}&text=${shareContent.text}`}>
												<img alt='twitter' className='is-not-hover' src={twitterIcon} />
												<img alt='twitter' className='is-hover' src={twitterHoverIcon} />
											</a>
											<a target='_blank' rel='noreferrer noopener' href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrlEncoded}`}>
												<img alt='facebook' className='is-not-hover' src={facebookIcon} />
												<img alt='facebook' className='is-hover' src={facebookHoverIcon} />
											</a>
											<a target='_blank' rel='noreferrer noopener' href={`mailto:?body=${shareContent.text}%0a${currentUrlEncoded}&subject=${shareContent.subject}`}>
												<img alt='email' className='is-not-hover' src={emailIcon} />
												<img alt='email' className='is-hover' src={emailHoverIcon} />
											</a>
											<CopyToClipboard text={currentUrl}>
												<button type='button' className='clear' onClick={handleCopyToClipboard}>
													<img alt='link' className='is-not-hover' src={linkIcon} />
													<img alt='link' className='is-hover' src={linkHoverIcon} />
												</button>
											</CopyToClipboard>
											<span
												className={justCopied ? 'indicator-profile-modal_share_copy-confirm is-visible' : 'indicator-profile-modal_share_copy-confirm'}>
												Copied to Clipboard
											</span>
										</div>
									)
							: null
						}
						</div>
						<div className='indicator-profile-modal_visualizations-section'>
							{
								metric && metric.yearsAvailable.includes(selectedYear)
									?
										(
											<React.Fragment>
												<div className='indicator-profile-modal_map-visual'>
													<Heatmap
														year={selectedYear}
														hidden={false}
														forCompare={false}
														simple={true}
														metric={metric}
													/>
												</div>
												<div className='indicator-profile-modal_map_legend is-mobile'>
													<div className='indicator-profile-modal_map_legend-text'>Worst</div>
													<div className='indicator-profile-modal_map_legend-tile worst'></div>
													<div className='indicator-profile-modal_map_legend-tile bad'></div>
													<div className='indicator-profile-modal_map_legend-tile neutral'></div>
													<div className='indicator-profile-modal_map_legend-tile good'></div>
													<div className='indicator-profile-modal_map_legend-tile best'></div>
													<div className='indicator-profile-modal_map_legend-text'>Best</div>
												</div>
												<div className='indicator-profile-modal_bar-visual is-desktop'>
													<SingleIndicatorVisual metric={metric} />
												</div>
											</React.Fragment>
										)
									:
										<React.Fragment>
											<div className='indicator-profile-modal_map-visual'>
												<Heatmap
													year={selectedYear}
													hidden={false}
													forCompare={false}
													simple={true}
													metric={metric}
												/>
											</div>
											<div className='indicator-profile-modal_no-visual'>
												{
													metric && metric.name === 'Residual Vote Rate'
														? 'N/A. Residual Vote Rate is not calculated for midterm elections.'
														: metric && constants.new2020MetricNames.includes(metric.name)
                      			? constants.metricAddedIn2020Text
														: 'N/A. This indicator is not available for this year.'
												}
											</div>
										</React.Fragment>
							}
						</div>
					</div>
					<div className='indicator-profile-modal_contents'>
						<div
							className='indicator-profile-modal_contents_description'
							dangerouslySetInnerHTML={{__html: metric ? metric.text.long_description : null}}
						/>
						<div
							className='indicator-profile-modal_contents_notes'
							dangerouslySetInnerHTML={{__html: metric ? metric.text.notes : null}}
						/>
					</div>
				</div>
				<div className='indicator-profile-modal_footer'>
					<div className='indicator-profile-modal_footer-content'>
						{
							previousMetric
								?
									(
										<div className='indicator-profile-modal_footer_previous'>
											<div className='is-desktop'>PREVIOUS</div>
											<div>
												<span>{previousMetric.displayName}</span>
												<div className='is-mobile'>PREVIOUS</div>
												<div className='indicator-profile-modal_footer_arrow'>
													<img src={previousArrow} alt='previous' onClick={() => dispatch(showIndicatorProfile(previousMetric.id))} />
													<img className='is-hover is-desktop' src={previousArrowHover} alt='previous' onClick={() => dispatch(showIndicatorProfile(previousMetric.id))} />
												</div>
											</div>
										</div>
									)
								: <span></span>
						}
						{
							nextMetric
								?
									(
										<div className='indicator-profile-modal_footer_next'>
											<div className='is-desktop'>NEXT</div>
											<div>
												<span>{nextMetric.displayName}</span>
												<div className='is-mobile'>NEXT</div>
												<div className='indicator-profile-modal_footer_arrow'>
													<img src={nextArrow} alt='next' onClick={() => dispatch(showIndicatorProfile(nextMetric.id))} />	
													<img className='is-hover is-desktop' src={nextArrowHover} alt='next' onClick={() => dispatch(showIndicatorProfile(nextMetric.id))} />
												</div>
											</div>
										</div>
									)
								: <span></span>
						}
					</div>
				</div>
			</div>
		</div>
  );
}
