import { 
  FETCH_RAW_DATA_SUCCESS,
  LOAD_COMPLETE,
  LOAD_FAILED,
  ENRICH_METRICS_SUCCESS,
  NORMALIZE_DATA_SUCCESS,
  CHANGE_SELECTED_YEAR,
  CHANGE_SELECTED_COMPARE_YEAR,
  CHANGE_SELECTED_METRICS,
  ENRICH_DATA_SUCCESS,
  SET_STATE_PROFILE,
  SET_INDICATOR_PROFILE,
  ENRICH_STATES_SUCCESS,
  SHOW_STATE_PROFILE,
  SHOW_INDICATOR_PROFILE,
  CLOSE_STATE_PROFILE,
  CLOSE_INDICATOR_PROFILE,
  SET_IS_SHOWING_SCATTER_PLOT,
  FETCH_FRONT_PAGE_SUCCESS,
  UPDATE_HOVERED_STATE_ABBV,
} from '../constants/action-types';
import { createHashHistory } from 'history';
import { getMetrics, getSelectedYear, getStates } from '../selectors';
import { getMetricYearsAvailable, isValidRawValue, enrichData, warnOnPotentialInvalidRawData, warnOnPotentialInvalidNormalizedData } from '../bizUtils';
import { csv } from 'd3';
import url2008raw from '../data/raw/2008.csv';
import url2010raw from '../data/raw/2010.csv';
import url2012raw from '../data/raw/2012.csv';
import url2014raw from '../data/raw/2014.csv';
import url2016raw from '../data/raw/2016.csv';
import url2018raw from '../data/raw/2018.csv';
import url2020raw from '../data/raw/2020.csv';
import url2022raw from '../data/raw/2022.csv';
import url2008normalized from '../data/normalized/2008-normalized.csv';
import url2010normalized from '../data/normalized/2010-normalized.csv';
import url2012normalized from '../data/normalized/2012-normalized.csv';
import url2014normalized from '../data/normalized/2014-normalized.csv';
import url2016normalized from '../data/normalized/2016-normalized.csv';
import url2018normalized from '../data/normalized/2018-normalized.csv';
import url2020normalized from '../data/normalized/2020-normalized.csv';
import url2022normalized from '../data/normalized/2022-normalized.csv';
import { doGet, handleError } from '../utils';
import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const yearUrlsRaw = {
  2008: url2008raw,
  2010: url2010raw,
  2012: url2012raw,
  2014: url2014raw,
  2016: url2016raw,
  2018: url2018raw,
  2020: url2020raw,
  2022: url2022raw,
};

const yearUrlsNormalized = {
  2008: url2008normalized,
  2010: url2010normalized,
  2012: url2012normalized,
  2014: url2014normalized,
  2016: url2016normalized,
  2018: url2018normalized,
  2020: url2020normalized,
  2022: url2022normalized,
};

const host = process.env.REACT_APP_CONTENT_HOST;
const jsonApiHost = `${host}/jsonapi`;

export function fetchData() {
  return async (dispatch, getState) => {
    try {
      // set initially selected year
      dispatch(changeSelectedYear(Object.keys(yearUrlsRaw).sort().reverse()[0], true));

      // load raw data from files
      const rawDataPromises = [];
      Object.keys(yearUrlsRaw).forEach(year =>
        rawDataPromises.push(fetchRawDataForYear(yearUrlsRaw[year], year))
      );
      const allRawData = await Promise.all(rawDataPromises);
      const rawDataByYear = allRawData.reduce((mapped, yearData) => {
        mapped[yearData.year] = yearData.rawData;
        return mapped;
      }, {});
      dispatch({ type: FETCH_RAW_DATA_SUCCESS, payload: rawDataByYear });

      // load other files (metric metadata and static content from drupal)
      // use those files to store enriched state and metric objects as well as front page content
      const [indicatorAttributes, stateAttributes, frontPageContents] =
        await Promise.all([fetchIndicatorAttributes(), fetchStateAttributes(), fetchFrontPageContent()]);
      const metrics = getMetrics(getState());
      const enrichedMetrics = enrichMetrics(allRawData, metrics, indicatorAttributes);
      dispatch({ type: ENRICH_METRICS_SUCCESS, payload: enrichedMetrics });
      const states = getStates(getState());
      const enrichedStates = await enrichStates(states, stateAttributes, indicatorAttributes);
      dispatch({ type: ENRICH_STATES_SUCCESS, payload: enrichedStates});
      dispatch({ type: FETCH_FRONT_PAGE_SUCCESS, payload: frontPageContents});

      // enrich raw data with additional metadata
      const enrichedRawData = enrichData(allRawData, metrics);
      dispatch({ type: ENRICH_DATA_SUCCESS, payload: enrichedRawData });

      // load normalized data from file
      const normalizedDataPromises = [];
      Object.keys(yearUrlsNormalized).forEach(year =>
        normalizedDataPromises.push(fetchNormalizedDataForYear(yearUrlsNormalized[year], year))
      );
      const allNormalizedData = await Promise.all(normalizedDataPromises);
      const normalizedDataByYear = allNormalizedData.reduce((mapped, yearData) => {
        mapped[yearData.year] = yearData.normalizedData;
        return mapped;
      }, {});
      warnOnPotentialInvalidNormalizedData(normalizedDataByYear, enrichedMetrics);
      dispatch({ type: NORMALIZE_DATA_SUCCESS, payload: normalizedDataByYear });

      // show site (hide loading symbol)
      dispatch({ type: LOAD_COMPLETE });
    } catch(error) {
      handleError(error, 'Unexpected failure while fetching app data', false);
      dispatch({ type: LOAD_FAILED });
    }
  }
}

async function fetchFrontPageContent() {
  const url = `${jsonApiHost}/node/article?filter%5Bpromote%5D=1&include=field_image.field_media_image,field_author&sort=-field_date&page%5Blimit%5D=2`;
  const data = await doGet(url);
  if (!has(data, 'data') || isEmpty(data.data)) throw new Error('Promoted articles response missing data property');
  if (!has(data, 'included') || isEmpty(data.included)) throw new Error('Promoted articles response missing included property');
  const articles = data.data
    .filter(n => n.type === 'node--article')
    .map(n => {
      const attributes = n.attributes || {};
      const relationships = n.relationships || {};
      const authorExternalId = get(relationships.field_author, 'data.id');
      const imageExternalId = get(relationships.field_image, 'data.id');
      const authorNode = data.included.find(n => n.id === authorExternalId);
      const imageNode = data.included.find(n => n.id === imageExternalId);
      const imageFileExternalId = get(imageNode, 'relationships.thumbnail.data.id');
      const imageFileNode = data.included.find(n => n.id === imageFileExternalId);
      const relativeImageUrl = get(imageFileNode, 'attributes.uri.url');
      const imageAlt = get(imageNode, 'relationships.thumbnail.data.meta.alt') || 'Article Thumbnail';
      const date = new Date(attributes.field_date);
      return {
        date: getFormattedDate(date),
        subtitle: get(attributes.field_subtitle, 'value'),
        title: attributes.title,
        articleUrl: `${host}${get(attributes.path, 'alias')}`,
        author: get(authorNode, 'attributes.title'),
        imageUrl: relativeImageUrl ? `${host}${relativeImageUrl}` : null,
        imageAlt,
      }
    });
  if (articles.length < 2) throw new Error(`Expected 2 promoted articles but got ${articles.length}`);
  return {
    spotlight: 'The Elections Performance Index (EPI) is the premier assessment tool to evaluate election administration across the United States.',
    update1: articles[0],
    update2: articles[1]
  };
}

const getFormattedDate = (date) => {
  const month = date.getUTCMonth() < 9 ? `0${date.getUTCMonth() + 1}` : `${date.getUTCMonth() + 1}`;
  const day = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;
  return  `${month}.${day}.${date.getUTCFullYear()}`;
};

async function fetchIndicatorAttributes() {
  const url = `${jsonApiHost}/indicator`;
  const data = await doGet(url);
  return data.data.map(node => ({ ...node.attributes, externalId: node.id }));
}

async function fetchStateAttributes() {
  // default json api in drupal enforces limit of 50 even if you ask for more
  // this could be more elegant... but no reason for it
  const url1 = `${jsonApiHost}/node/state?include=field_years&page[offset]=0&page[limit]=50`;
  const data1 = await doGet(url1);
  const url2 = `${jsonApiHost}/node/state?include=field_years&page[offset]=50&page[limit]=1`;
  const data2 = await doGet(url2);
  const data = [...data1.data, ...data2.data];
  const included = [...data1.included, ...data2.included];

  return data.map(node => ({
    stateName: node.attributes.title,
    electionWebsite: get(node.attributes.field_election_website, 'uri'),
    featuredIndicators: get(node.relationships.field_featured_indicators, 'data'),
    votingConditions:
      has(node.relationships.field_years, 'data') && !isEmpty()
        ? node.relationships.field_years.data
          .filter(stateYear => !isEmpty(stateYear) && has(stateYear, 'id'))
          .map(stateYear => {
            const stateYearsNode = included.find(includedItem => includedItem.id === stateYear.id);
            return stateYearsNode ? stateYearsNode.attributes : {};
          })
        : [],
  }));
}

async function enrichStates(states, stateAttributes, indicatorAttributes) {
  const enrichedStates = [...states].map(state => {
    const stateNode = stateAttributes.find(node => node.stateName === state.name);
    if (!stateNode) return { ...state };
    return {
      ...state,
      electionWebsite: stateNode.electionWebsite,
      featuredIndicators: stateNode.featuredIndicators
        ? stateNode.featuredIndicators.map(fi => {
            const featuredIndicator = indicatorAttributes.find(i => i.externalId === fi.id);
            return featuredIndicator ? featuredIndicator.title : '';
          })
        : [],
      votingConditions:
        stateNode.votingConditions 
          ? stateNode.votingConditions.reduce((conditions, condition) => {
              conditions[condition.field_year] = {
                chiefElectionOfficial: condition.field_chief_election_official,
                chiefElectionOfficialTitle: condition.field_chief_election_official_ti,
                inPersonEarlyVoting: condition.field_in_person_early_voting,
                mailVoting: condition.field_mail_voting,
              };
              return conditions;
            }, {})
          : {},
    };
  });
  return enrichedStates;
}

async function fetchRawDataForYear(url, year) {
  const rawData = await csv(url);

  // ensure all numbers are stored as strings
  rawData.forEach(row => {
    Object.keys(row).forEach(metricName => row[metricName] = isValidRawValue(row[metricName]) ? parseFloat(row[metricName]) : row[metricName]);
  })
  return { year, rawData };
}

async function fetchNormalizedDataForYear(url, year) {
  const normalizedData = await csv(url);
  normalizedData.forEach(row => {
    Object.keys(row).forEach(metricName => row[metricName] = !isNaN(row[metricName]) ? parseFloat(row[metricName]) : row[metricName]);
  })
  return { year, normalizedData };
}

function enrichMetrics(rawData, metrics, indicatorAttributes) {
  const updatedMetrics = [...metrics];
  updatedMetrics.forEach(m => {
    const texts = indicatorAttributes.find(indicator => indicator.title === m.name);
    m.yearsAvailable = getMetricYearsAvailable(rawData, m);
    m.text  = texts ? Object.keys(texts).reduce((formatted, key) => {
      formatted[key] = has(texts[key], 'value') ? texts[key].value : texts[key];
      return formatted;
    }, {}) : {};
  });
  updatedMetrics.forEach(m => {
    m.displayName = has(m.text, 'field_display_name') && !isEmpty(m.text.field_display_name) ? m.text.field_display_name : m.name;
  });
  warnOnPotentialInvalidRawData(rawData, updatedMetrics);
  return updatedMetrics;
}

export function changeSelectedYear(year, skipUpdatedPath = false) {
  if (!skipUpdatedPath) {
    const history = createHashHistory();
    const currentSearch = history.location.search;
    if (currentSearch.includes('year=')) {
      const updatedSearch = currentSearch.replace(/year=[0-9]{4}/, `year=${year}`);
      const updatedPath = `${history.location.pathname}${updatedSearch}`;
      history.push(updatedPath);
    }
  }
  return { type: CHANGE_SELECTED_YEAR, payload: year}
}

export function changeSelectedComparedYear(year) {
  return { type: CHANGE_SELECTED_COMPARE_YEAR, payload: year}
}

export function changeSelectedMetrics(metrics) {
  return { type: CHANGE_SELECTED_METRICS, payload: metrics}
}

export function showStateProfile(stateAbbv) {
  return async (dispatch, getState) => {
    const selectedYear = getSelectedYear(getState());
    const history = createHashHistory();
    const currentPath = history.location.pathname;
    const updatedSearch = `?view=state-profile&state=${stateAbbv}&year=${selectedYear}`;
    if (history.location.search !== updatedSearch) {
      const updatedPath = `${currentPath}${updatedSearch}`;
      history.push(updatedPath);
    }
    dispatch({ type: SHOW_STATE_PROFILE});
  }
}

export function showIndicatorProfile(metricId) {
  return async (dispatch, getState) => {
    const selectedYear = getSelectedYear(getState());
    const history = createHashHistory();
    const currentPath = history.location.pathname;
    const updatedSearch = `?view=indicator-profile&indicator=${metricId}&year=${selectedYear}`;
    if (history.location.search !== updatedSearch) {
      const updatedPath = `${currentPath}${updatedSearch}`;
      history.push(updatedPath);
    }
    dispatch({ type: SHOW_INDICATOR_PROFILE});
  }
}

export function closeStateProfile() {
  const history = createHashHistory();
  const currentPath = history.location.pathname;
  history.push(currentPath);
  return { type: CLOSE_STATE_PROFILE};
}

export function closeIndicatorProfile() {
  const history = createHashHistory();
  const currentPath = history.location.pathname;
  history.push(currentPath);
  return { type: CLOSE_INDICATOR_PROFILE};
}

export function setStateProfile(stateAbbv) {
  return { type: SET_STATE_PROFILE, payload: stateAbbv};
}

export function setIndicatorProfile(metricId) {
  return { type: SET_INDICATOR_PROFILE, payload: metricId};
}

export function setIsShowingScatterPlot(isShowingScatterPlot) {
  return { type: SET_IS_SHOWING_SCATTER_PLOT, payload: isShowingScatterPlot};
}

export function updateHoveredStateAbbv(stateAbbv) {
  return { type: UPDATE_HOVERED_STATE_ABBV, payload: stateAbbv };
}
