import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { select, scaleLinear, selection, zoom, event, selectAll } from 'd3';
import { getNormalizedAverageByStateByYearSelectedMetrics, getStates } from '../../../selectors';
import { isValidNormalizedValue, getColorFromValue } from '../../../bizUtils';
import { showStateProfile } from '../../../actions';
import debounce from 'lodash/debounce';
import { isInternetExplorer } from '../../../utils';

const easeDurationMs = 300;

selection.prototype.moveToFront = function() {
  return this.each(function(){
  	this.parentNode.appendChild(this);
  });
};

selection.prototype.moveToBack = function() {
	return this.each(function() {
			var firstChild = this.parentNode.firstChild;
			if (firstChild) {
					this.parentNode.insertBefore(this, firstChild);
			}
	});
};

selection.prototype.moveToCirclesLayer = function() {
  return this.each(function(){
		select('.circles-wrapper').node().appendChild(this);
  });
};

selection.prototype.moveToTopLayer = function() {
  return this.each(function(){
		select('#plot-bound').node().appendChild(this);
  });
};

const convertCoords = (x,y, svg, element) => {
  const offset = svg.getBoundingClientRect();
	const matrix = element.getScreenCTM();
  return {
    x: (matrix.a * x) + (matrix.c * y) + matrix.e - offset.left,
		y: (matrix.b * x) + (matrix.d * y) + matrix.f - offset.top,
	};
}

const mapValueToDomain = (value, domainMin, domainMax) => {
	return (value - domainMin) / (domainMax - domainMin);
};

export default function ScatterPlot({ year, compareYear, hidden, selectedYearAverage, selectedCompareYearAverage }) {
	const dispatch = useDispatch();
	const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
	const states = useSelector(state => getStates(state));
	const lastHoverState = useRef(null);
	const mouseoutTimeoutId = useRef(null);

	const viewPortLength = 225;

	const getStateColor = useCallback((abbv) => {
		const stateAverage = normalizedAverageByStateByYear[year][abbv];
		return getColorFromValue(stateAverage);
	}, [normalizedAverageByStateByYear, year]);

	const getTextColor = useCallback((abbv) => {
		const stateAverage = normalizedAverageByStateByYear[year][abbv];
		if (isNaN(stateAverage)) {
			return '#1c1536';
		}

		if (stateAverage < .4) return '#ffffff';
		return '#1c1536';
	}, [normalizedAverageByStateByYear, year]);

	const d3Container = useRef(null);

	const getData = useCallback(() => {
		const averageByStateInYear = normalizedAverageByStateByYear[year];
		const averageByStateInCompareYear = normalizedAverageByStateByYear[compareYear];
		const scatterData = [];
		let minValue = 1,
				maxValue = 1;
		Object.keys(averageByStateInYear).forEach(abbv => {
			if (
				!isValidNormalizedValue(averageByStateInYear[abbv]) ||
				!isValidNormalizedValue(averageByStateInCompareYear[abbv])
			) {
				return;
			}
			if (averageByStateInYear[abbv] < minValue)
				minValue = averageByStateInYear[abbv];
			if (averageByStateInCompareYear[abbv] < minValue)
				minValue = averageByStateInCompareYear[abbv];
			if (averageByStateInYear[abbv] > maxValue)
				maxValue = averageByStateInYear[abbv];
			if (averageByStateInCompareYear[abbv] > maxValue)
				maxValue = averageByStateInCompareYear[abbv];
			scatterData.push({
				abbv: abbv,
				name: states.find(s => s.abbv === abbv).name,
				yearValue: averageByStateInYear[abbv],
				compareYearValue: averageByStateInCompareYear[abbv],
				color: getStateColor(abbv),
				textColor: getTextColor(abbv),
			});
		})
		return {
			minValue,
			maxValue,
			scatterData,
		}
	}, [normalizedAverageByStateByYear, year, compareYear, getStateColor, getTextColor, states]);

	const initializeTooltip = useCallback((data) => {
		selectAll('.scatter-plot-tooltip').remove();
		const tooltip = select('body').selectAll('#root')
			.data([{}].concat(...data))
			.enter()
			.append('div')
			.attr('class', 'scatter-plot-tooltip')
			.attr('id', (d) => `scatter-plot-tooltip-${d.abbv}`)
		tooltip.append('div').attr('class', 'scatter-plot-tooltip_title');
		tooltip.append('div').attr('class', 'scatter-plot-tooltip_details');
	}, []);

	const handleMouseOut = (stateData) => {
		if (lastHoverState.current === stateData.abbv) {
			lastHoverState.current = null;
			select('#plot-outline').style('opacity', '1');
			select('#hover-background').style('opacity', '0')
			mouseoutTimeoutId.current = setTimeout(() =>
				select('#hover-background').moveToBack(), easeDurationMs);
			select('.delta-line').style('stroke', '#525971').style('transition', `stroke ${easeDurationMs/2}ms ease`);
			selectAll('.average-line').style('stroke', '#525971').style('transition', `stroke ${easeDurationMs/2}ms ease`);

			// detach mouseleave
			select(`#circle-${stateData.abbv}`).on('mouseleave', null);
		}
	};

	// use debounced version to prevent jitteriness when moving around states
	const handleMouseOutDebounced = debounce(handleMouseOut, 150);

	const handleMouseOver = (stateData, tooltip, stateUnderMouse) => {
		if (mouseoutTimeoutId.current) clearTimeout(mouseoutTimeoutId.current);
		select('#hover-background').moveToFront();
		select('#plot-outline').style('opacity', '.5');
		setTimeout(() => select('#hover-background').style('opacity', '.9'));
		select(stateUnderMouse).moveToTopLayer();
		select('.delta-line').style('stroke', '#ffffff').style('transition', `stroke ${easeDurationMs*2}ms ease`);
		selectAll('.average-line').style('stroke', '#ffffff').style('transition', `stroke ${easeDurationMs*2}ms ease`);
		updateTooltipOnMouseOver(tooltip, stateUnderMouse, stateData);

		// attach mouseleave (if done before, then it would fire when circle is moved to front on IE)
		// https://stackoverflow.com/questions/3686132/move-active-element-loses-mouseout-event-in-internet-explorer
		if (isInternetExplorer()) {
			// the danger here is that if the user mouses over too quickly, the mouseout won't be triggered at all
			// they can then retrigger/cleanup by hovering again over any state
			setTimeout(() => select(`#circle-${stateData.abbv}`).on('mouseleave', function(d) {
				select(`#scatter-plot-tooltip-${d.abbv}`).style('visibility', 'hidden').style('opacity', '0');
				handleMouseOutDebounced(d);
			}), 50);
		} else {
			select(`#circle-${stateData.abbv}`).on('mouseleave', function(d) {
				select(`#scatter-plot-tooltip-${d.abbv}`).style('visibility', 'hidden').style('opacity', '0');
				handleMouseOutDebounced(d);
			});
		}

		return stateData.abbv;
	};

	const updateTooltipOnMouseOver = (tooltip, stateUnderMouse, stateData) => {
		// get rid of all other tooltips first
		// normally the mouseout event would handle this, but the user may have left it before we attached the mouseout event
		selectAll('.scatter-plot-tooltip').style('visibility', 'hidden').style('opacity', '0');

		setTooltipPosition(stateUnderMouse, tooltip);

		tooltip.select('.scatter-plot-tooltip_title').text(stateData.name);
		let detailsHtml = '';
		[year, compareYear].forEach(thisYear => {
			const value = normalizedAverageByStateByYear[thisYear][stateData.abbv];
			if (isValidNormalizedValue(value)) {
				const rank = Object.keys(normalizedAverageByStateByYear[thisYear]).indexOf(stateData.abbv) + 1;
				detailsHtml += `${thisYear}: ${Math.round(value*100)}% (#${rank})<br>`;
			}
		});
		tooltip.select('.scatter-plot-tooltip_details').html(detailsHtml);
	};

	const setTooltipPosition = (stateUnderMouse, tooltip) => {
		const stateBoundingBox = select(stateUnderMouse).node().getBBox();
		const {x ,y} = convertCoords(
			stateBoundingBox.x + stateBoundingBox.width/2,
			stateBoundingBox.y,
			select('body').node(),
			stateUnderMouse
		);

		tooltip
			.style('visibility', 'visible')
			.style('opacity', '1')
			.style('left', `${x - 100}px`)
			tooltip.style('top', `${y - 70}px`);
	};

	useEffect(() => {
		if (!normalizedAverageByStateByYear || !compareYear) return;

		const svg = select(d3Container.current);
		svg.selectAll('g').remove();

		const {
			scatterData,
			minValue,
			maxValue,
		} = getData(normalizedAverageByStateByYear[year], normalizedAverageByStateByYear[compareYear]);
		initializeTooltip(scatterData);
		const width = viewPortLength, height = viewPortLength;
		const scatterGroup = svg
			.append('g')
			.attr('class', 'scatter-wrapper')
			.attr('transform', 'scale(.8, .8)');

		const domainMin = minValue-.05*maxValue;
		const domainMax = maxValue+.05*maxValue
		const x = scaleLinear()
			.domain([domainMin, domainMax])
			.range([ 0, width ]);
		const y = scaleLinear()
			.domain([domainMin, domainMax])
			.range([ height, 0]);

		// add year labels to the axes
		scatterGroup.append('text')
			.attr('class', 'axis-label')
			.attr('x', .504*viewPortLength) 
			.attr('y', 1.104*viewPortLength)
			.attr('text-anchor', 'middle')
			.attr('class', 'maps_maps_scatter-plot_axis-title')
			.text(compareYear);
		scatterGroup.append('text')
			.attr('class', 'axis-label')
			.attr('text-anchor', 'middle')
			.attr('x', -.073*viewPortLength)
			.attr('y', .5*viewPortLength)
			.attr('transform', `rotate(-90,${-.073*viewPortLength},${.5*viewPortLength})`)
			.style('text-anchor', 'middle')
			.attr('class', 'maps_maps_scatter-plot_axis-title')
			.text(year);

		// add bounding rectangle
		const plotBound = scatterGroup
			.append('g')
			.attr('id', 'plot-clip-bound')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr('id', 'plot-bound')
			.attr('width', '100%')
			.attr('height', '100%')
			.attr('pointer-events', 'all');
		plotBound
			.append('rect')
			.attr('id', 'hover-background')
			.style('pointer-events', 'none')
			.style('transition', `opacity ${easeDurationMs}ms ease`)
			.attr('width', '100%')
			.attr('height', '100%')
			.attr('stroke', 'none')
			.attr('fill', '#1c1536')
			.style('opacity', 0);
		plotBound
			.append('rect')
			.attr('id', 'plot-outline')
			.style('transition', `opacity ${easeDurationMs}ms ease`)
			.attr('width', '100%')
			.attr('height', '100%')
			.attr('stroke', '#525971')
			.attr('stroke-width', 0.5)
			.attr('fill', 'none');

		

	// add diagonal with improved/worsened labels
	plotBound.append('line')
		.attr('class', 'delta-line')
		.style('transition', `stroke ${easeDurationMs*2}ms ease`)
		.style('stroke', '#525971')
		.attr('stroke-width', 0.25)
		.style('stroke-dasharray', ('1.5, 1.5'))
		.attr('x1', 0)
		.attr('y1', viewPortLength)
		.attr('x2', viewPortLength)
		.attr('y2', 0);
	plotBound.append('text')
		.attr('id', 'delta-label-improved')
		.attr('text-anchor', 'middle')
		.attr('x', .08*viewPortLength)
		.attr('y', .885*viewPortLength)
		.attr('transform', `rotate(-45,${.08*viewPortLength},${.885*viewPortLength})`)
		.style('text-anchor', 'middle')
		.attr('class', 'maps_maps_scatter-plot_worsened-improved-title')
		.text('Improved');
	plotBound.append('text')
		.attr('id', 'delta-label-worsened')
		.attr('text-anchor', 'middle')
		.attr('x', .128*viewPortLength)
		.attr('y', .928*viewPortLength)
		.attr('transform', `rotate(-45,${.128*viewPortLength},${.928*viewPortLength})`)
		.style('text-anchor', 'middle')
		.attr('class', 'maps_maps_scatter-plot_worsened-improved-title')
		.text('Worsened');

	// add averages for each year, with line and labels
	if (!isNaN(selectedYearAverage)) {
		const averageInDomain = mapValueToDomain(selectedYearAverage, domainMin, domainMax);
		plotBound.append('line')
			.attr('class', 'average-line')
			.style('transition', `stroke ${easeDurationMs*2}ms ease`)
			.style('stroke', '#525971')
			.attr('stroke-width', 0.25)
			.style('stroke-dasharray', ('1.5, 1.5'))
			.attr('x1', 0)
			.attr('y1', (1-averageInDomain)*viewPortLength)
			.attr('x2', viewPortLength)
			.attr('y2', (1-averageInDomain)*viewPortLength);
		plotBound.append('text')
			.attr('class', 'average-label')
			.attr('id', 'average-label-selected-year')
			.attr('text-anchor', 'middle')
			.attr('x', .1*viewPortLength)
			.attr('y', (1-averageInDomain)*viewPortLength - .025*viewPortLength)
			.attr('class', 'maps_maps_scatter-plot_average-title')
			.text(`Average (${Math.round(selectedYearAverage*100)}%)`);
	}
	if (!isNaN(selectedCompareYearAverage)) {
		const averageInDomain = mapValueToDomain(selectedCompareYearAverage, domainMin, domainMax);
		plotBound.append('line')
			.attr('class', 'average-line')
			.style('transition', `stroke ${easeDurationMs*2}ms ease`)
			.style('stroke', '#525971')
			.attr('stroke-width', 0.25)
			.style('stroke-dasharray', ('1.5, 1.5'))
			.attr('x1', averageInDomain*viewPortLength)
			.attr('y1', 0)
			.attr('x2', averageInDomain*viewPortLength)
			.attr('y2', viewPortLength);
		plotBound.append('text')
			.attr('class', 'average-label')
			.attr('id', 'average-label-selected-compare-year')
			.attr('text-anchor', 'middle')
			.attr('x', averageInDomain*viewPortLength + .04*viewPortLength)
			.attr('y', .9*viewPortLength)
			.attr('transform', `rotate(-90, ${averageInDomain*viewPortLength + .04*viewPortLength}, ${.9*viewPortLength})`)
			.attr('class', 'maps_maps_scatter-plot_average-title')
			.text(`Average (${Math.round(selectedCompareYearAverage*100)}%)`);
	}

	// add actual circles for each state with hover/click actions
	const scatterCircleGroup = plotBound.append('g')
		.attr('class', 'circles-wrapper')
		.style('overflow', 'hidden')
		.selectAll('dot')
		.data(scatterData);
	const circleEnters = scatterCircleGroup
		.enter()
		.append('g')
		.attr('class', 'circles')
		.attr('width', '10')
		.style('transition', `opacity ${easeDurationMs}ms ease`)
		.attr('id', function (d) { return `circle-${d.abbv}`; } )
		.on('mouseover', function(d) {
			if (lastHoverState.current !== d.abbv) {
				lastHoverState.current = d.abbv;
				handleMouseOver(d, select(`#scatter-plot-tooltip-${d.abbv}`), this);
			}
		})
		.on('click', function(d) {
			dispatch(showStateProfile(d.abbv));
		});
	circleEnters.append('circle')
		.attr('class', 'scatter-circle')
		.attr('cx', function (d) { return x(d.compareYearValue); } )
		.attr('cy', function (d) { return y(d.yearValue); } )
		.attr('r', 4)
		.style('fill', (d) => d.color)
		.attr('stroke-width', '.25px')
		.attr('stroke', '#1c1536');
	circleEnters.append('text')
		.attr('x', function (d) { return x(d.compareYearValue); } )
		.attr('y', function (d) { return y(d.yearValue) + 1.2; } )
		.text((d) => d.abbv)
		.style('fill', (d) => d.textColor)
		.style('font-size', '3.5px')
		.style('pointer-events', 'none')
		.attr('text-anchor', 'middle');

	select('.scatter-wrapper').call(
		zoom()
			// restrict to zooming to only zooming one to a certain size
			.scaleExtent([1, 5]) 
			.on('zoom', function () {
				select('#plot-bound')
					.attr('transform', event.transform);
				select('#hover-background')
					.attr('transform', event.transform);
				selectAll('line')
					.attr('transform', event.transform);
				select('#plot-outline')
					.attr('transform', event.transform);
					

				// text selection is only working with IDs, not sure why but nbd
				if (!isNaN(selectedYearAverage)) {
					selectAll('#average-label-selected-year')
						.attr('transform', event.transform);
				}
				if (!isNaN(selectedCompareYearAverage)) {
					const averageInDomain = mapValueToDomain(selectedCompareYearAverage, domainMin, domainMax);
					selectAll('#average-label-selected-compare-year')
						.attr(
							'transform',
							`translate(${event.transform['x']},${event.transform['y']}) scale(${event.transform['k']}) rotate(-90, ${averageInDomain*viewPortLength + .04*viewPortLength}, ${.9*viewPortLength})`
						);
				}
				selectAll('#delta-label-improved')
					.attr(
						'transform',
						`translate(${event.transform['x']},${event.transform['y']}) scale(${event.transform['k']}) rotate(-45,${.08*viewPortLength},${.885*viewPortLength})`
					);
				selectAll('#delta-label-worsened')
					.attr(
						'transform',
						`translate(${event.transform['x']},${event.transform['y']}) scale(${event.transform['k']}) rotate(-45,${.128*viewPortLength},${.928*viewPortLength})`
					);

				var newXScale = event.transform.rescaleX(x);
				var newYScale = event.transform.rescaleY(y);
				selectAll('.circles circle')
					.attr('cx', function(d) {return newXScale(d.compareYearValue)})
					.attr('cy', function(d) {return newYScale(d.yearValue)});
				selectAll('.circles text')
					.attr('x', function(d) {return newXScale(d.compareYearValue)})
					.attr('y', function(d) {return newYScale(d.yearValue) + 1.2});
			})
	);

	// prevent overflow during zoom
	svg.append("defs")
		.append("svg:clipPath")
		.attr("id", "clip")
		.append("svg:rect")
		.attr("id", "clip-rect")
		.attr("x", -.05*height)
		.attr("y", -.15*height)
		.attr('height', height*1.2)
		.attr('width', width*1.2);
	select('#plot-clip-bound')
		.attr("clip-path", "url(#clip)")
		.attr("height", height)
		.attr("width", width)
		.attr("class", "graph-group");

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [normalizedAverageByStateByYear, compareYear]);

	if (!normalizedAverageByStateByYear || !compareYear) return null;
	return (
		<svg
			className={hidden ? 'is-hidden' : null}
			viewBox={`0 0 ${viewPortLength} ${viewPortLength}`}
			preserveAspectRatio='xMidYMid meet'
			ref={d3Container}
		/>
	);
}