import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Heatmap from './visualizations/Heatmap';
import ScatterPlot from './visualizations/ScatterPlot';
import NavFilterBar from './NavFilterBar';

import { setIsShowingScatterPlot } from '../../actions';
import { useFoundation } from '../../utils';
import {
  getNormalizedAverageByStateByYearSelectedMetrics,
  getSelectedYear,
  getSelectedCompareYear,
  getIfMetricsAreFiltered,
  getVisibleMetrics,
  getFrontPage,
  getEnricheddDataByYearByMetric,
  getNormalizedDataByYearByMetric,
  getSelectedAvailableMetrics,
  getCompareMetricDeltaWarning,
} from '../../selectors';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { Button } from 'react-foundation';
import upArrow from '../images/up-arrow.svg';
import downArrow from '../images/down-arrow.svg';
import {
  isValidNormalizedValue,
  getNationwideAverageDisplayForMetricAndYear,
  invalidValueDecimalToLongName,
  InvalidValues,
} from '../../bizUtils';

// this could be broken up for readability, but at a high level
// it populates the value, display value, and summary text for the year and (if applicable) comapre year
// for the average aross the chosen indicators and, if hovered, state
const getAveragesAndSummaryText = (
  selectedAvailableMetrics,
  metrics,
  normalizedDataByYearAndMetric,
  enrichedDataByYearAndMetric,
  selectedYear,
  isComparing,
  selectedCompareYear,
  normalizedAverageByStateForSelectedYear,
  normalizedAverageByStateForSelectedCompareYear,
  areMetricsFiltered,
  hoveredStateAbbv
) => {
  let selectedYearAverage,
      selectedCompareYearAverage,
      selectedYearAverageDisplayValue,
      selectedCompareYearAverageDisplayValue,
      summaryText,
      summaryCompareText;
  
  if (selectedAvailableMetrics.length === 1) {
    const metric = metrics.find(m => m.name === selectedAvailableMetrics[0]);
    if (hoveredStateAbbv) {
      ({
        average: selectedYearAverage,
        averageDisplayValue: selectedYearAverageDisplayValue,
        text: summaryText
      } = getAveragesAndSummaryTextForMetricAndSingleState(
        metric,
        selectedYear,
        enrichedDataByYearAndMetric,
        hoveredStateAbbv
      ));
      if (isComparing) {
        ({
          average: selectedCompareYearAverage,
          averageDisplayValue: selectedCompareYearAverageDisplayValue,
          text: summaryCompareText
        } = getAveragesAndSummaryTextForMetricAndSingleState(
          metric,
          selectedCompareYear,
          enrichedDataByYearAndMetric,
          hoveredStateAbbv
        ));
      }
    } else {
      const averageDisplay = getNationwideAverageDisplayForMetricAndYear(
        metric,
        normalizedDataByYearAndMetric,
        enrichedDataByYearAndMetric,
        selectedYear,
        true
      );
      selectedYearAverage = averageDisplay.percentageAsDecimal;
      selectedYearAverageDisplayValue = averageDisplay.value;
      summaryText = averageDisplay.text;
      if (isComparing) {
        const averageCompareDisplay = getNationwideAverageDisplayForMetricAndYear(
          metric,
          normalizedDataByYearAndMetric,
          enrichedDataByYearAndMetric,
          selectedCompareYear,
          true
        );
        selectedCompareYearAverage = averageCompareDisplay.percentageAsDecimal;
        selectedCompareYearAverageDisplayValue = averageCompareDisplay.value;
        summaryCompareText = averageDisplay.text;
      }
    }
  } else {
    if (hoveredStateAbbv) {
      selectedYearAverage = normalizedAverageByStateForSelectedYear[hoveredStateAbbv];
      selectedYearAverageDisplayValue = isValidNormalizedValue(selectedYearAverage)
        ? `${Math.round(selectedYearAverage * 100)}%`
        : invalidValueDecimalToLongName(selectedYearAverage);
      summaryText = isValidNormalizedValue(selectedYearAverage)
        ? areMetricsFiltered ? 'Average' : 'EPI Average'
        : '';
      if (isComparing) {
        selectedCompareYearAverage = normalizedAverageByStateForSelectedCompareYear[hoveredStateAbbv];
        selectedCompareYearAverageDisplayValue = isValidNormalizedValue(selectedCompareYearAverage)
          ? `${Math.round(selectedCompareYearAverage * 100)}%`
          : invalidValueDecimalToLongName(selectedCompareYearAverage);
        summaryCompareText = isValidNormalizedValue(selectedCompareYearAverage)
          ? areMetricsFiltered ? 'Average' : 'EPI Average'
          : '';
      }
    } else {
      selectedYearAverage =
        Object.values(normalizedAverageByStateForSelectedYear).filter(value => isValidNormalizedValue(value)).reduce((sum, stateValue) => sum += stateValue, 0)
        / Object.values(normalizedAverageByStateForSelectedYear).filter(value => isValidNormalizedValue(value)).length;
      selectedYearAverageDisplayValue = `${Math.round(selectedYearAverage * 100)}%`;
      summaryText = areMetricsFiltered ? 'Average' : 'EPI Average';
      if (isComparing) {
        selectedCompareYearAverage = 
          Object.values(normalizedAverageByStateForSelectedCompareYear).filter(value => isValidNormalizedValue(value)).reduce((sum, stateValue) => sum += stateValue, 0)
          / Object.values(normalizedAverageByStateForSelectedCompareYear).filter(value => isValidNormalizedValue(value)).length;
        selectedCompareYearAverageDisplayValue = `${Math.round(selectedCompareYearAverage * 100)}%`;
        summaryCompareText = areMetricsFiltered ? 'Average' : 'EPI Average';
      }
    }
  }

  return {
    selectedYearAverage,
    selectedCompareYearAverage,
    selectedYearAverageDisplayValue,
    selectedCompareYearAverageDisplayValue,
    summaryText,
    summaryCompareText
  };
};

const getAveragesAndSummaryTextForMetricAndSingleState = (
  metric,
  year,
  enrichedDataByYearAndMetric,
  stateAbbv
) => {
  let average, averageDisplayValue, text;
  text = '';
  if (metric.yearsAvailable.includes(year)) {
    average =
      enrichedDataByYearAndMetric[year][metric.name][stateAbbv].value;
      averageDisplayValue =
      enrichedDataByYearAndMetric[year][metric.name][stateAbbv].displayValue;
    if (has(InvalidValues, average)) average = InvalidValues[average];
  } else {
    average = 'not a number';
    averageDisplayValue = '';
  }

  return {
    average,
    averageDisplayValue,
    text
  };
}

export default function Map() {
  useFoundation();
  const dispatch = useDispatch();
  const isShowingScatterPlot = useSelector(state => state.isShowingScatterPlot);
  const selectedAvailableMetrics = useSelector(state => getSelectedAvailableMetrics(state));
  const hoveredStateAbbv = useSelector(state => state.hoveredStateAbbv);
  const areMetricsFiltered = useSelector(state => getIfMetricsAreFiltered(state));
  const [metricDrawerIsOpen, setMetricDrawerIsOpen] = useState(false);
  const selectedYear = useSelector(state => getSelectedYear(state));
  const selectedCompareYear = useSelector(state => getSelectedCompareYear(state));
  const metrics = useSelector(state => getVisibleMetrics(state));
  const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
  const enrichedDataByYearAndMetric = useSelector(state => getEnricheddDataByYearByMetric(state));
  const normalizedDataByYearAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));
  const compareMetricDeltaWarning = useSelector(state => getCompareMetricDeltaWarning(state));
  const isComparing = !isEmpty(selectedCompareYear);
  const normalizedAverageByStateForSelectedYear = normalizedAverageByStateByYear[selectedYear];
  const normalizedAverageByStateForSelectedCompareYear = normalizedAverageByStateByYear[selectedCompareYear];
  const frontPage = useSelector(state => getFrontPage(state));

  const {
    selectedYearAverage,
    selectedCompareYearAverage,
    selectedYearAverageDisplayValue,
    selectedCompareYearAverageDisplayValue,
    summaryText,
    summaryCompareText,
  } = getAveragesAndSummaryText(
    selectedAvailableMetrics,
    metrics,
    normalizedDataByYearAndMetric,
    enrichedDataByYearAndMetric,
    selectedYear,
    isComparing,
    selectedCompareYear,
    normalizedAverageByStateForSelectedYear,
    normalizedAverageByStateForSelectedCompareYear,
    areMetricsFiltered,
    hoveredStateAbbv
  );

  window.$('#root').on('opened.zf.offCanvas', (event) => {
    if (event.target.id === 'metrics-drawer') {
      setMetricDrawerIsOpen(true);
    }
  });

  window.$('#root').on('close.zf.offCanvas', (event) => {
    if (event.target.id === 'metrics-drawer') {
      setMetricDrawerIsOpen(false);
    }
  });

  const handleImagError = (e) => {
    e.target.style.display = 'none';
  };

  useEffect(() => {
    // if scatter plot is showing and user stops comparing via state selector, unshow scatter plot
    // as scatter plot is only applicabler to comparison
    if (!isComparing) {
      dispatch(setIsShowingScatterPlot(false))
    }
  }, [dispatch, isComparing]);

  return (
    <div>
      <div className='map'>
        <NavFilterBar />
        <div className={isComparing ? 'map_contents is-comparing' : 'map_contents'}>
          <div className={isComparing ? 'maps_legend-wrapper is-comparing' : 'maps_legend-wrapper'}>
            <div className='map_legend'>
              <div className='map_legend-text'>Worst</div>
              <div className='map_legend-tile worst'></div>
              <div className='map_legend-tile bad'></div>
              <div className='map_legend-tile neutral'></div>
              <div className='map_legend-tile good'></div>
              <div className='map_legend-tile best'></div>
              <div className='map_legend-text'>Best</div>
            </div>
            {
              isNaN(selectedYearAverage)
                ? null
                : 
                  (
                    <div className='maps_maps_details is-mobile'>
                      <span>{selectedYearAverageDisplayValue}</span>
                      <span>{summaryText}</span>
                    </div>
                  )
            }
          </div>
          <div className={ metricDrawerIsOpen ? 'map_maps metric-drawer-is-opened' : 'map_maps' }>
            {/* Safari isn't happy when we push existing svgs around in flexbox, so instead hide and show, dont mount/unmount */}
            <div className={ isShowingScatterPlot || isComparing ? 'is-hidden' : 'maps_maps_not-compare' }>
              <Heatmap
                year={selectedYear}
                hidden={ isShowingScatterPlot || isComparing }
                forCompare={false}
              />
            </div>
            <div className={ isShowingScatterPlot || !isComparing ? 'is-hidden' : 'heatmap-compare-tooltip' }>
              <div className='heatmap-tooltip_title' />
            </div>
            <div className={ isShowingScatterPlot || !isComparing ? 'is-hidden' : 'maps_maps_compare' }>
              <Heatmap
                year={selectedYear}
                hidden={ isShowingScatterPlot || !isComparing }
                forCompare={true}
              />
              <div className='maps_maps_compare_title'>
                <p>{selectedYear}</p>
                {
                  selectedYearAverage !== selectedCompareYearAverage &&
                  !isNaN(selectedYearAverage) &&
                  !isNaN(selectedCompareYearAverage)
                    ? <img alt='delta-arrow' src={selectedYearAverage > selectedCompareYearAverage ? upArrow : downArrow} />
                    : null
                }
              </div>
              <div className='maps_maps_compare_details'>
                {
                  isNaN(selectedYearAverage)
                    ? null
                    : 
                      (
                        <React.Fragment>
                          <span>{selectedYearAverageDisplayValue}</span>
                          <span>{summaryText}</span>
                        </React.Fragment>
                      )
                }
              </div>
            </div>
            <div className={ isShowingScatterPlot || !isComparing ? 'is-hidden' : 'maps_maps_compare is-comparison-map' }>
              <Heatmap
                year={selectedCompareYear}
                hidden={ isShowingScatterPlot || !isComparing }
                forCompare={true}
              />
              <div className='maps_maps_compare_title'>
                <p>{selectedCompareYear}</p>
                {
                  selectedYearAverage !== selectedCompareYearAverage &&
                  !isNaN(selectedYearAverage) &&
                  !isNaN(selectedCompareYearAverage)
                    ? <img alt='delta-arrow' src={selectedYearAverage < selectedCompareYearAverage ? upArrow : downArrow} />
                    : null
                }
              </div>
              <div className='maps_maps_compare_details'>
                {
                  isNaN(selectedCompareYearAverage)
                    ? null
                    : 
                      (
                        <React.Fragment>
                          <span>{selectedCompareYearAverageDisplayValue}</span>
                          <span>{summaryCompareText}</span>
                        </React.Fragment>
                      )
                }
              </div>
            </div>
            <div className={ isShowingScatterPlot ? 'maps_maps_scatter-plot' : 'is-hidden' }>
              <ScatterPlot
                selectedYearAverage={selectedYearAverage}
                selectedCompareYearAverage={selectedCompareYearAverage}
                year={selectedYear}
                compareYear={selectedCompareYear}
                hidden={ !isShowingScatterPlot }
              />
            </div>
          </div>
        </div>
        <div className={isShowingScatterPlot ? 'map_footer is-minimized' : 'map_footer'}>
          <span></span>
          <span className={isComparing ? 'map_footer_toggle-visualization-wrapper' : 'map_footer_toggle-visualization-wrapper is-invisible'}>
            <span
              className={
                selectedAvailableMetrics.length < 5 && !isShowingScatterPlot
                ? 'map_footer_too-few-metrics-wrapper'
                : 'map_footer_too-few-metrics-wrapper is-invisible'
              }
            >
              <Button
                className='clear map_footer_toggle-visualization'
                disabled={true}
              >
                View Scatter Plot
              </Button>
              <div className='map_footer_too-few-metrics'>
                Please select 5 or more indicators from the filter list to view the scatter plot.
              </div>
            </span>
            <span className={ isShowingScatterPlot ? null : 'is-invisible' }>
              <Button
                className='clear map_footer_toggle-visualization'
                onClick={() => dispatch(setIsShowingScatterPlot(!isShowingScatterPlot))}
              >
                View Map
              </Button>
            </span>
            <span
              className={
                !isShowingScatterPlot && selectedAvailableMetrics.length >= 5
                  ? null
                  : 'is-invisible'
              }
            >
              <Button
                className='clear map_footer_toggle-visualization'
                onClick={() => dispatch(setIsShowingScatterPlot(!isShowingScatterPlot))}
              >
                View Scatter Plot
              </Button>
            </span>
          </span>
          <div className='map_footer-warning is-mobile'>
              {compareMetricDeltaWarning}
          </div>
          <span>
            <Button
              data-toggle='select-state-modal'
              className={isShowingScatterPlot ? 'clear map_footer_select-state is-invisible' : 'clear map_footer_select-state'}
            >
              Select a State
            </Button>
          </span>
          <span className='map_footer-description is-mobile'>
            How did each state perform in the last national election? Explore the data by selecting filters
            or selecting a state from the dropdown above.
          </span>
          <span></span>
        </div>
      </div>
      <div className='landing-content'>
        <div className='landing-content_above-updates'>
          <div className='landing-content_spotlight'>{frontPage.spotlight}</div>
          <a href={`${process.env.REACT_APP_CONTENT_HOST}/about`} className='landing-content_about-button'>ABOUT THE EPI</a>
        </div>
        <div className='landing-content_updates'>
          <div className='card' onClick={() => window.open(frontPage.update1.articleUrl,'_self')}>
            {frontPage.update1.imageUrl && <img onError={handleImagError} src={frontPage.update1.imageUrl} alt={frontPage.update1.imageAlt} />}
            <div className='card-section'>
              <div className='card-section_top'>
                <h6>{frontPage.update1.date}</h6>
                <h4>{frontPage.update1.title}</h4>
                <p dangerouslySetInnerHTML={{__html: frontPage.update1.subtitle}} />
              </div>
              { frontPage.update1.author ? <span className='landing-content_updates_author'>By {frontPage.update1.author}</span> : <span></span> }
              
            </div>
          </div>
          <div className='card' onClick={() => window.open(frontPage.update2.articleUrl,'_self')}>
            {frontPage.update2.imageUrl && <img onError={handleImagError} src={frontPage.update2.imageUrl} alt={frontPage.update2.imageAlt} />}
            <div className='card-section'>
              <div className='card-section_top'>
                <h6>{frontPage.update2.date}</h6>
                <h4>{frontPage.update2.title}</h4>
                <p dangerouslySetInnerHTML={{__html: frontPage.update2.subtitle}} />
              </div>
            { frontPage.update2.author ? <span className='landing-content_updates_author'>By {frontPage.update2.author}</span> : <span></span> }
            </div>
          </div>
        </div>
        <div className='landing-content_below-updates'>
          <a href={`${process.env.REACT_APP_CONTENT_HOST}/learn`} className='landing-content_more-articles'>VIEW MORE ARTICLES</a>
        </div>
      </div>
    </div>
  );
}
