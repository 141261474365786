export const FETCH_RAW_DATA_SUCCESS = 'FETCH_RAW_DATA_SUCCESS';
export const LOAD_COMPLETE = 'LOAD_COMPLETE';
export const ENRICH_METRICS_SUCCESS = 'ENRICH_METRICS_SUCCESS';
export const NORMALIZE_DATA_SUCCESS = 'NORMALIZE_DATA_SUCCESS';
export const CHANGE_SELECTED_YEAR = 'CHANGE_SELECTED_YEAR';
export const CHANGE_SELECTED_COMPARE_YEAR = 'CHANGE_SELECTED_COMPARE_YEAR';
export const CHANGE_SELECTED_METRICS = 'CHANGE_SELECTED_METRICS';
export const ENRICH_DATA_SUCCESS = 'ENRICH_DATA_SUCCESS';
export const SET_STATE_PROFILE = 'SET_STATE_PROFILE';
export const SET_INDICATOR_PROFILE = 'SET_INDICATOR_PROFILE';
export const ENRICH_STATES_SUCCESS = 'ENRICH_STATES_SUCCESS';
export const LOAD_FAILED = 'LOAD_FAILED';
export const SHOW_STATE_PROFILE = 'SHOW_STATE_PROFILE';
export const SHOW_INDICATOR_PROFILE= 'SHOW_INDICATOR_PROFILE';
export const CLOSE_STATE_PROFILE = 'CLOSE_STATE_PROFILE';
export const CLOSE_INDICATOR_PROFILE= 'CLOSE_INDICATOR_PROFILE';
export const SET_IS_SHOWING_SCATTER_PLOT = 'SET_IS_SHOWING_SCATTER_PLOT';
export const FETCH_FRONT_PAGE_SUCCESS = 'FETCH_FRONT_PAGE_SUCCESS';
export const UPDATE_HOVERED_STATE_ABBV = 'UPDATE_HOVERED_STATE_ABBV';
