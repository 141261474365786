import { createSelector } from 'reselect';
import { getStateNormalizedAverageForYear, getElectionType, isValidNormalizedValue, isValidRawValue } from '../bizUtils';
import mapKeys from 'lodash/mapKeys';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

const getStatesFromStore = state => state.states;
const getStateShapesFromStore = state => state.stateShapes;
const getRawDataFromStore = state => state.data.raw;
const getEnrichedDataFromStore = state => state.data.enriched;
const getNormalizedDataFromStore = state => state.data.normalized;
const getMetricsFromStore = state => state.metrics;
const getSelectedYearFromStore = state => state.selection.year;
const getSelectedCompareYearFromStore = state => state.selection.compareYear;
const getSelectedMetricsFromStore = state => state.selection.metrics;
const getStateProfileFromStore = state => state.stateProfile;
const getIndicatorProfileFromStore = state => state.indicatorProfile;
const getMetricGroupsFromStore = state => state.metricGroups;
const getFrontPageFromStore = state => state.frontPage;

export const getStates = createSelector(
	[getStatesFromStore],
	(states) => sortBy({...states}, 'name'),
);

export const getFrontPage = createSelector(
	[getFrontPageFromStore],
	(frontPage) => frontPage,
);

export const getMetricGroups = createSelector(
	[getMetricGroupsFromStore],
	(metricGroups) => metricGroups,
);

export const getStateProfile = createSelector(
	[getStateProfileFromStore],
	(stateProfile) => stateProfile,
);

export const getIndicatorProfile = createSelector(
	[getIndicatorProfileFromStore],
	(indicatorProfile) => indicatorProfile,
);

export const getStateShapes = createSelector(
	[getStateShapesFromStore],
	(stateShapes) => stateShapes,
);

export const getRawDataByYear = createSelector(
	[getRawDataFromStore],
	(rawData) => rawData,
);

export const getEnrichedDataByYear = createSelector(
	[getEnrichedDataFromStore],
	(enrichedData) => enrichedData,
);

export const getNormalizedDataByYear = createSelector(
	[getNormalizedDataFromStore],
	(normalizedData) => normalizedData,
);

export const getEnricheddDataByYearByMetric = createSelector(
	[getEnrichedDataFromStore, getMetricsFromStore],
	(enrichedData, metrics) => {
		const enrichedByYearAndMetric = {};
		Object.keys(enrichedData).forEach(year => {
			enrichedByYearAndMetric[year] = {};
			metrics.filter(m => m.yearsAvailable.includes(year)).forEach(metric => {
				const arrayOfMetricForYear =
				enrichedData[year].map(yearData => ({ value: yearData[metric.name], stateAbbv: yearData.state_abbv }))
				arrayOfMetricForYear.sort((a, b) => {
					if (!isValidRawValue(a.value)) {
						if (!isValidRawValue(b.value)) return 0;
						return -1;
					}
					if (!isValidRawValue(b.value)) return 1;
					if (a.value > b.value) return 1
					if (a.value < b.value) return -1;
					return 0;
				});
				enrichedByYearAndMetric[year][metric.name] = arrayOfMetricForYear.reduce((grouped, thisState) => {
					grouped[thisState.stateAbbv] = thisState.value;
					return grouped;
				}, {});
			})
		});
		return enrichedByYearAndMetric;
	}
);

export const getNormalizedDataByYearByMetric = createSelector(
	[getNormalizedDataFromStore, getMetricsFromStore, getStatesFromStore],
	(normalizedData, metrics, states) => {
		const normalizedByYearAndMetric = {};
		Object.keys(normalizedData).forEach(year => {
			normalizedByYearAndMetric[year] = {};
			metrics.filter(m => m.yearsAvailable.includes(year)).forEach(metric => {
				const arrayOfMetricForYear =
					normalizedData[year].map(yearData => ({ value: yearData[metric.name], stateAbbv: yearData.state_abbv }))
				arrayOfMetricForYear.sort((a, b) => {
					if (!isValidNormalizedValue(a.value)) {
						if (!isValidNormalizedValue(b.value)) return 0;
						return -1;
					}
					if (!isValidNormalizedValue(b.value)) return 1;
					if (a.value > b.value) return 1
					if (a.value < b.value) return -1;

					const stateA = states.find(s => s.abbv === a.stateAbbv);
					const stateB = states.find(s => s.abbv === b.stateAbbv);
					if (stateA.name > stateB.name) return -1;
					if (stateB.name > stateA.name) return 1;
					return 0;
				});
				normalizedByYearAndMetric[year][metric.name] = arrayOfMetricForYear.reduce((grouped, thisState) => {
					grouped[thisState.stateAbbv] = thisState.value;
					return grouped;
				}, {});
			})
		});
		return normalizedByYearAndMetric;
	}
);

export const getMetrics = createSelector(
	[getMetricsFromStore],
	(metrics) => sortBy(metrics, 'name'),
);

export const getVisibleMetrics = createSelector(
	[getMetricsFromStore],
	(metrics) => sortBy(metrics.filter(m => !m.hidden), 'name'),
);

export const getVisibleAvailableMetrics = createSelector(
	[getMetricsFromStore, getSelectedYearFromStore, getSelectedCompareYearFromStore],
	(metrics, selectedYear, selectedCompareYear) => {
		return sortBy(metrics.filter(m => {
			const isAvailable = m.yearsAvailable.includes(selectedYear) || (selectedCompareYear && m.yearsAvailable.includes(selectedCompareYear));
			return !m.hidden && isAvailable;
		}), 'name')
	},
);

export const getVisibleMetricsGrouped = createSelector(
	[getMetricsFromStore, getMetricGroups],
	(metrics, metricGroups) => {
		const sortedMetrics = sortBy(metrics.filter(m => !m.hidden), 'groupSortOrder');
		const groupedMetrics = metricGroups.map(mg => ({
			...mg,
			metrics: sortedMetrics.filter(m => m.groupId === mg.id),
		}));
		return groupedMetrics;
	}
);

export const getSelectedYear = createSelector(
	[getSelectedYearFromStore],
	(year) => year,
);

export const getSelectedCompareYear = createSelector(
	[getSelectedCompareYearFromStore],
	(year) => year,
);

export const getSelectedMetrics = createSelector(
	[getSelectedMetricsFromStore],
	(metrics) => sortBy(metrics, 'name'),
);

export const getSelectedAvailableMetrics = createSelector(
	[getSelectedMetrics, getMetricsFromStore, getSelectedYearFromStore, getSelectedCompareYearFromStore],
	(selectedMetrics, metrics, selectedYear, selectedCompareYear) => {
		return selectedMetrics.filter(metricName => {
			const metric = metrics.find(m => m.name === metricName)
			return metric.yearsAvailable.includes(selectedYear) || (selectedCompareYear && metric.yearsAvailable.includes(selectedCompareYear));
		});
	},
);

export const getAllAvailableYears = createSelector(
	[getRawDataFromStore],
	(rawData) => Object.keys(rawData).sort().reverse(),
);

export const getAvailableYearsOfSameTypeAsSelected = createSelector(
	[getRawDataFromStore, getSelectedYearFromStore],
	(rawData, selectedYear) => {
		const electionTypeOfSelectedYear = getElectionType(selectedYear);
		return Object.keys(rawData)
			.filter(year => getElectionType(year) === electionTypeOfSelectedYear && year !== selectedYear)
			.sort()
			.reverse();
	}
)

export const getNormalizedAverageByStateByYearAllMetrics = createSelector(
	[getNormalizedDataByYear, getMetrics, getAllAvailableYears, getStatesFromStore],
	(allNormalizedData, metrics, availableYears, states) => {
		const normalizedAverageByStateByYear = {};
		availableYears.forEach(year => {
			normalizedAverageByStateByYear[year] =
				getNormalizedDataForYearAndMetrics(allNormalizedData, metrics, year, metrics.map(m => m.name), states);
		});
		return normalizedAverageByStateByYear;
	},
);

export const getNormalizedAverageByStateByYearSelectedMetrics = createSelector(
	[getNormalizedDataByYear, getMetrics, getAllAvailableYears, getSelectedMetricsFromStore, getStatesFromStore],
	(allNormalizedData, metrics, availableYears, selectedMetrics, states) => {
		const normalizedAverageByStateByYear = {};
		availableYears.forEach(year => {
			normalizedAverageByStateByYear[year] =
				getNormalizedDataForYearAndMetrics(allNormalizedData, metrics, year, selectedMetrics, states);
		});
		return normalizedAverageByStateByYear;
	},
);

const getNormalizedDataForYearAndMetrics = (allNormalizedData, metrics, year, selectedMetrics, states) => {
	const averageByStateArray = [];
	allNormalizedData[year].forEach(stateYear => {
		const stateAverageForYear = getStateNormalizedAverageForYear(
			allNormalizedData,
			stateYear.state_abbv,
			selectedMetrics,
			metrics,
			year
		);
		averageByStateArray.push({ state: stateYear.state_abbv, value: stateAverageForYear });
	});
	averageByStateArray.sort((a, b) => {
		const aState = states.find(s => s.abbv === a.state);
		const bState = states.find(s => s.abbv === b.state);
		return a.value < b.value
			? 1
			: a.value > b.value
				? -1
				: aState.name > bState.name
					? 1
					: aState.name < bState.name
						? -1
						: 0;
		});
	const averageByState = mapKeys(averageByStateArray, 'state');
	Object.keys(averageByState).forEach(state => averageByState[state] = averageByState[state].value);
	return {...averageByState};
}

export const getIfMetricsAreFiltered = createSelector(
	[getVisibleAvailableMetrics, getSelectedAvailableMetrics],
	(availableMetrics, selectedAvailableMetrics) => {
		return availableMetrics.length !== selectedAvailableMetrics.length;
	}
);

export const getCompareMetricDeltaWarning = createSelector(
	[getSelectedYearFromStore, getSelectedCompareYearFromStore, getSelectedAvailableMetrics, getMetricsFromStore],
	(selectedYear, selectedCompareYear, selectedAvailableMetrics, metrics) => {
		if (!selectedCompareYear) return null;
		const selectedMetricsInSelectedYear = metrics.filter(m => 
			selectedAvailableMetrics.includes(m.name) && m.yearsAvailable.includes(selectedYear)
		);
		const selectedMetricsInSelectedCompareYear = metrics.filter(m => 
			selectedAvailableMetrics.includes(m.name) && m.yearsAvailable.includes(selectedCompareYear)
		);
		if (isEqual(selectedMetricsInSelectedYear, selectedMetricsInSelectedCompareYear)) return null;
		const metricsOnlyAvailableInSelectedYear = selectedMetricsInSelectedYear
			.filter(m => 
				!selectedMetricsInSelectedCompareYear.map(m2 => m2.name).includes(m.name)
			);
		const metricsOnlyAvailableInSelectedCompareYear = selectedMetricsInSelectedCompareYear
			.filter(m => 
				!selectedMetricsInSelectedYear.map(m2 => m2.name).includes(m.name)
			);
		const selectedYearWarning = metricsOnlyAvailableInSelectedYear.length === 0
			? ''
			: metricsOnlyAvailableInSelectedYear.length === 1
				? `${metricsOnlyAvailableInSelectedYear[0].displayName} is not calculated into overall average for ${selectedCompareYear}`
				: `${metricsOnlyAvailableInSelectedYear.map(m => m.displayName).join(', ')} are not calculated into overall average for ${selectedCompareYear}`;
		const selectedCompareYearWarning = metricsOnlyAvailableInSelectedCompareYear.length === 0
			? ''
			: metricsOnlyAvailableInSelectedCompareYear.length === 1
				? `${metricsOnlyAvailableInSelectedCompareYear[0].displayName} is not calculated into overall average for ${selectedYear}`
				: `${metricsOnlyAvailableInSelectedCompareYear.map(m => m.displayName).join(', ')} are not calculated into overall average for ${selectedYear}`;
		return `${selectedYearWarning} ${selectedCompareYearWarning}`
	}
)
