import React from 'react';
import { useFoundation } from '../../../utils';
import { useSelector, useDispatch }  from 'react-redux';
import { getNormalizedAverageByStateByYearSelectedMetrics, getStates } from '../../../selectors';
import upArrow from '../../images/up-arrow.svg';
import downArrow from '../../images/down-arrow.svg';
import { isValidNormalizedValue, InvalidValues, getKeyByValue } from '../../../bizUtils';
import { showStateProfile } from '../../../actions';

export default function RankCompareColumn({ year, otherYear, setCurrentHoverState, currentHoverState }) {
	useFoundation();
	const dispatch = useDispatch();
	const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
  const states = useSelector(state => getStates(state));
	const averageByStateInSelectedYear = normalizedAverageByStateByYear[year];

	return (
		<div className='rank_compare-column'>
			<div className='rank_compare-column_title'>
				{year}
			</div>
			<div className='rank_compare-column_states'>
				{Object.keys(normalizedAverageByStateByYear[year]).map((stateAbbv, index) => {
					const state = states.find(s => s.abbv === stateAbbv);
					const otherYearValue = normalizedAverageByStateByYear[otherYear][stateAbbv];
					const otherYearRank = Object.keys(normalizedAverageByStateByYear[otherYear]).indexOf(stateAbbv) + 1;
					return (
						<div
							key={stateAbbv}
							className={
								currentHoverState === stateAbbv
									? 'rank_compare-column_state is-hovered'
									: currentHoverState === null
										? 'rank_compare-column_state'
										: 'rank_compare-column_state other-is-hovered'
								}
							onMouseEnter={() => setCurrentHoverState(stateAbbv, null)}

							// should leave current state and other-year state as hovered after user leaves area, to make comparison easier
							// onMouseLeave={() => setCurrentHoverState(null, stateInfo.abbv)}
							onClick={() => dispatch(showStateProfile(stateAbbv))}
						>
							<div>
								<div className='rank_compare-column_state_rank-and-name'>
									<div className='rank_compare-column_state_rank'>
										{index + 1}
									</div>
									<div className='rank_compare-column_state_name'>
										{state.name}
									</div>
									<div className='rank_compare-column_state_abbv'>
										{stateAbbv}
									</div>
								</div>
								<div className='rank_compare-column_state_percentage'>
									{
										isValidNormalizedValue(averageByStateInSelectedYear[stateAbbv])
											? `${Math.round(averageByStateInSelectedYear[stateAbbv]*100)}%`
											:  getKeyByValue(averageByStateInSelectedYear[stateAbbv], InvalidValues)
									}
								</div>
								<div className='rank_compare-column_state_other-year is-desktop'>
									<div>
										{
											isValidNormalizedValue(otherYearValue) &&
											isValidNormalizedValue(averageByStateInSelectedYear[stateAbbv]) &&
											otherYearValue !== averageByStateInSelectedYear[stateAbbv]
												?
													<img
														src={otherYearValue > averageByStateInSelectedYear[stateAbbv] ? downArrow : upArrow}
														alt='arrow'
													/>
												: null
										}
										{`${otherYear}: `}
										{
											isValidNormalizedValue(otherYearValue)
												? `${Math.round(otherYearValue*100)}% (#${otherYearRank})`
												: getKeyByValue(otherYearValue, InvalidValues)
										}
									</div>
								</div>
							</div>
						</div>
					)})
				}
			</div>
		</div>
	)
}
