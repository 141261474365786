import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'd3';
import { getEnricheddDataByYearByMetric } from '../../../selectors';
import { MetricDirections, isValidRawValue, getDisplayValue } from '../../../bizUtils';

export default function IndicatorVsNation({ metric, year, stateAbbv }) {
	const enrichedDataByYear = useSelector(state => getEnricheddDataByYearByMetric(state));
	const d3Container = useRef(null);
	const viewPortHeight = 95;
	const viewPortWidth = 300;

	useEffect(() => {
		const lineHeightInVieport = viewPortHeight * .8;
		const svg = select(d3Container.current);
		svg.selectAll('g').remove();
		const dataSet = enrichedDataByYear[year][metric.name];
		const allValues = Object.values(dataSet).map(s => s.value).filter(v => isValidRawValue(v));
		const minValue = Math.min(...allValues);
		const maxValue = Math.max(...allValues);
		const worstDisplayValue =
			metric.direction === MetricDirections.Reverse
				? Object.values(dataSet).find(s => s.value === maxValue).shortDisplayValue
				: Object.values(dataSet).find(s => s.value === minValue).shortDisplayValue;
		const bestDisplayValue =
			metric.direction === MetricDirections.Reverse
				? Object.values(dataSet).find(s => s.value === minValue).shortDisplayValue
				: Object.values(dataSet).find(s => s.value === maxValue).shortDisplayValue;
		const lineMarginLeft = .045 * worstDisplayValue.length;
		const lineMarginRight = .045 * bestDisplayValue.length;

		const average = allValues.length ? allValues.reduce((sum, v) => sum + v) / allValues.length : 0;
		const averageMapped = (average - minValue) / (maxValue - minValue);
		const averageDisplayValue = getDisplayValue(metric, average, metric.calculatedValueDenominator);
		const averageLocation = metric.direction === MetricDirections.Reverse
			? (1 - averageMapped) * viewPortWidth * (1 - lineMarginLeft - lineMarginRight) + lineMarginLeft * viewPortWidth
			: averageMapped * viewPortWidth * (1 - lineMarginLeft - lineMarginRight) + lineMarginLeft * viewPortWidth;

		const stateValueMapped = stateAbbv ? (dataSet[stateAbbv].value - minValue) / (maxValue - minValue) : null;
		const stateValueLocation = metric.direction === MetricDirections.Reverse
			? (1 - stateValueMapped) * viewPortWidth * (1 - lineMarginLeft - lineMarginRight) + lineMarginLeft * viewPortWidth
			: stateValueMapped * viewPortWidth * (1 - lineMarginLeft - lineMarginRight) + lineMarginLeft * viewPortWidth;
		const stateValueColor = stateValueLocation > averageLocation
				? '#37d463'
				: '#f3485a';

		const mainGroup = svg
			.append('g');
		mainGroup.append('text')
			.attr('alignment-baseline', 'central')
			.attr('text-anchor', 'start')
			.attr('class', 'indicator-vs-nation_label')
			.attr('x', 0)
			.attr('y', lineHeightInVieport)
			.text(worstDisplayValue);
		mainGroup.append('text')
			.attr('alignment-baseline', 'central')
			.attr('text-anchor', 'end')
			.attr('class', 'indicator-vs-nation_label')
			.attr('x', viewPortWidth)
			.attr('y', lineHeightInVieport)
			.text(bestDisplayValue);
		mainGroup.append('line')
			.style('stroke', '#525971')
			.attr('stroke-width', 2)
			.attr('x1', averageLocation)
			.attr('y1', lineHeightInVieport)
			.attr('x2', averageLocation)
			.attr('y2', lineHeightInVieport - 23);
		mainGroup.append('text')
			.attr('text-anchor', 'middle')
			.attr('class', 'indicator-vs-nation_average')
			.attr('x', averageLocation)
			.attr('y', lineHeightInVieport - 31)
			.text(averageDisplayValue);
		mainGroup.append('line')
			.style('stroke', '#b1bad5')
			.attr('stroke-width', 6.7)
			.attr('x1', lineMarginLeft*viewPortWidth)
			.attr('y1', lineHeightInVieport)
			.attr('x2', viewPortWidth*(1-lineMarginRight))
			.attr('y2', lineHeightInVieport);

		if (stateAbbv && !isNaN(stateValueLocation)) {
			mainGroup.append('line')
				.style('stroke', '#f0f2f5')
				.style('stroke-opacity', .95)
				.attr('stroke-width', 8)
				.attr('x1', stateValueLocation)
				.attr('y1', lineHeightInVieport + 6)
				.attr('x2', stateValueLocation)
				.attr('y2', lineHeightInVieport - 6);
			mainGroup.append('line')
				.style('stroke', stateValueColor)
				.attr('stroke-width', 5)
				.attr('x1', stateValueLocation)
				.attr('y1', lineHeightInVieport + 6)
				.attr('x2', stateValueLocation)
				.attr('y2', lineHeightInVieport - 6);
		}
	}, [enrichedDataByYear, year, metric, stateAbbv]);

	return (
		<svg
			className='indicator-vs-nation'
			viewBox={`0 0 ${viewPortWidth} ${viewPortHeight}`}
			preserveAspectRatio='xMidYMid meet'
			ref={d3Container}
		/>
	);
}