import React from 'react';
import { useSelector, useDispatch }  from 'react-redux';
import { getSelectedYear, getStates, getNormalizedDataByYearByMetric, getEnrichedDataByYear } from '../../../selectors';
import { getColorFromValue, isValidNormalizedValue, MetricDirections } from '../../../bizUtils';
import { showStateProfile } from '../../../actions';

export default function SingleIndicatorVisual({ metric }) {
  const dispatch = useDispatch();
  const selectedYear = useSelector(state => getSelectedYear(state));
  const states = useSelector(state => getStates(state));
  const normalizedDataByStateAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));
  const enrichedDataByYear = useSelector(state => getEnrichedDataByYear(state));

  const normalizedDataByStateForMetric = normalizedDataByStateAndMetric[selectedYear][metric.name];
  const enrichedDataByStateForMetric = enrichedDataByYear[selectedYear]
    .reduce((grouped, thisState) => {
      grouped[thisState.state_abbv] = thisState[metric.name];
      return grouped;
    }, {});

  const handleClickState = (event, stateAbbv) => {
    event.stopPropagation();
    dispatch(showStateProfile(stateAbbv));
  }

  return (
    <div className='single-indicator-visual'>
      <div className='single-indicator-visual_bars'>
        {
          Object.keys(normalizedDataByStateForMetric).map((stateAbbv, index) => {
            const stateInfo = states.find(stateInfo => stateInfo.abbv === stateAbbv);
            const normalizedValue = normalizedDataByStateForMetric[stateAbbv];
            const rank = Object.keys(normalizedDataByStateForMetric)
                .reverse()
                .indexOf(stateAbbv) + 1;
            const height = isValidNormalizedValue(normalizedValue)
              ? metric.direction === MetricDirections.Reverse
                // need to always show value above 0 so there is something visible
                ? (((1 - normalizedValue + .5) / 1.5 )*100)
                : (((normalizedValue + .5) / 1.5 )*100)
              : 10;
            const tooltipText = isValidNormalizedValue(normalizedValue)
              ? `${stateInfo.name}: ${enrichedDataByStateForMetric[stateAbbv].displayValue} (#${rank})`
              : `${stateInfo.name}: ${enrichedDataByStateForMetric[stateAbbv].displayValue}`;
            return (
              <React.Fragment key={index}>
                <div
                  className='single-indicator-visual_bar-wrapper'
                  onClick={(event) => handleClickState(event, stateAbbv)}
                  style={{
                    height: `${height}%`,
                  }}
                >
                  <div className='single-indicator-visual_bar-background-overlay'></div>
                  <div className='single-indicator-visual_bar-tooltip is-desktop'>
                    {tooltipText}
                  </div>
                  <div
                    className='single-indicator-visual_bar'
                    style={{
                      backgroundColor: getColorFromValue(normalizedValue, true)
                    }}
                  >
                  </div>
                </div>
              </React.Fragment>
            );
          })
        }
      </div>
       <div className='single-indicator-visual_visualization_legend'>
        <div>WORST</div>
        <div>BEST</div>
      </div>
    </div>
  );
}
