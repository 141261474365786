import { useEffect } from 'react';
import axios from 'axios';

// when a component is mounted, we need to provide it with the foundation JS
export const useFoundation = () => {
	useEffect(() => {
		window.jQuery(document).ready($ => ($(document).foundation()));
	})
}

export const isMobile = () => {
	return window.innerWidth <= 640;
}

export const isInternetExplorer = () => {
	const ua = navigator.userAgent;
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}

export const handleError = (error, prefix, throwAfter = false) => {
	/*
	if (process.env.NODE_ENV !== 'production') {
		console.error(`${prefix}: Error:`, error.message, error.stack);
	} else {
		console.error(prefix);
	}
	*/
	console.error(`${prefix}: Error:`, error.message, error.stack);

	if (throwAfter) {
		throw new Error(`${prefix}: ${error.message}`);
	}
}

export const doGet = async (url) => {
	let data;
	if (window.fetch) {
		const fetchResponse = await window.fetch(url);
		if (fetchResponse.status !== 200) {
			throw new Error(`Unexpected response code from ${url}: ${fetchResponse.status}.`);
		}
		data = await fetchResponse.json();
	} else {
		// axios throws exception for non 200-300, so no need to handle explicitly
		const response = await axios.get(url);
		data = response.data;
	}
	return data;
}

export const constants = {
	metricAddedIn2020Text: 'This indicator was added in 2020, and is not available for previous years.',
	new2020MetricNames: ['ERIC Membership', 'Risk-Limiting Audits', 'Disability Access'],
}
