import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SelectAState from './data/SelectAState';
import StateProfile from './data/StateProfile';
import IndicatorProfile from './data/IndicatorProfile';
import { setStateProfile, setIndicatorProfile, changeSelectedYear } from '../actions';
import { createHashHistory } from 'history';
import queryString from 'query-string';
import {
  getStateProfile,
  getIndicatorProfile,
  getAllAvailableYears,
  getVisibleMetrics,
  getStates,
  getSelectedYear,
} from '../selectors';

export default function Modals() {
  const dispatch = useDispatch();
  const loadComplete = useSelector(state => state.loadComplete);
  const stateProfile = useSelector(state => getStateProfile(state));
  const indicatorProfile = useSelector(state => getIndicatorProfile(state));
  const visibleMetrics = useSelector(state => getVisibleMetrics(state));
  const selectedYear = useSelector(state => getSelectedYear(state));
  const states = useSelector(state => getStates(state));
  const allAvailableYears = useSelector(state => getAllAvailableYears(state));
  const location = useLocation();
  const stateProfileCloseTimeoutRefs = useRef([]);
  const indicatorProfileCloseTimeoutRefs = useRef([]);

  // handle when query params change, inculding onload and while app is loaded  
  useEffect(() => {
    const easeDurationMs = 300;

    const validateSelectedYear = (year) => {
      if (allAvailableYears.includes(year)) {
        return year;
      }
      return allAvailableYears[0];
    }

    const validateIndicatorProfileMetricId = (metricId) => {
      if (visibleMetrics.map(m => m.id).includes(metricId)) {
        return metricId;
      }
      return visibleMetrics.map(m => m.id)[0];
    }

    const validateStateProfileAbbv = (stateAbbv) => {
      if (states.map(s => s.abbv).includes(stateAbbv)) {
        return stateAbbv;
      }
      return states.map(s => s.abbv)[0];
    }

    // handle query params
    if (loadComplete) {
      const history = createHashHistory();
      const values = queryString.parse(history.location.search);
      const year = validateSelectedYear(values.year);
      switch (values.view) {
        case 'state-profile':
          if (selectedYear !== year) {
            dispatch(changeSelectedYear(year));
          }

          const stateAbbv = validateStateProfileAbbv(values.state);
          if (stateAbbv !== stateProfile.currentStateAbbv) {
            dispatch(setIndicatorProfile(null));
            dispatch(setStateProfile(stateAbbv));
            window.$('#state-profile-modal').addClass('force-fade').addClass('raised');

            setTimeout(() => {
              // close other modals
              window.$('.full.reveal:visible').not('#state-profile-modal').each(function() {
                setTimeout(() => window.$(this).addClass('force-fade'), easeDurationMs);
                const thisTimeout =
                  setTimeout(() => window.$(this).removeClass('force-fade').foundation('close'), easeDurationMs*2);
                if (window.$(this).is('#indicator-profile-modal')) indicatorProfileCloseTimeoutRefs.current.push(thisTimeout);
              });

              // open state profile
              stateProfileCloseTimeoutRefs.current.forEach(timeout => clearTimeout(timeout));
              stateProfileCloseTimeoutRefs.current = [];
              window.$('#state-profile-modal').foundation('open');
              window.$('#state-profile-modal').removeClass('force-fade')
              setTimeout(() => window.$('#state-profile-modal').removeClass('raised'), easeDurationMs*5);
            });
          }
          break;
        case 'indicator-profile':
          if (selectedYear !== year) {
            dispatch(changeSelectedYear(year));
          }

          const metricId = validateIndicatorProfileMetricId(values.indicator);
          if (metricId !== indicatorProfile.currentMetricId) {
            const switchingWhileOpen = indicatorProfile.currentMetricId !== null;
            dispatch(setIndicatorProfile(metricId));
            dispatch(setStateProfile(null));
            if (!switchingWhileOpen) window.$('#indicator-profile-modal').addClass('force-fade');

            setTimeout(() => {
              // close other modals
              window.$('.full.reveal:visible').not('#indicator-profile-modal').each(function() {
                setTimeout(() => window.$(this).addClass('force-fade'), easeDurationMs);
                const thisTimeout =
                  setTimeout(() => window.$(this).removeClass('force-fade').foundation('close'), easeDurationMs*2);
                if (window.$(this).is('#state-profile-modal')) stateProfileCloseTimeoutRefs.current.push(thisTimeout);
              });

              // open state profile
              indicatorProfileCloseTimeoutRefs.current.forEach(timeout => clearTimeout(timeout));
              indicatorProfileCloseTimeoutRefs.current = [];
              window.$('#indicator-profile-modal').foundation('open');
              window.$('#indicator-profile-modal').removeClass('force-fade');
            });
          }
          break;
        default:
          // clear out states if they were set
          dispatch(setIndicatorProfile(null));
          dispatch(setStateProfile(null));
          
          window.$('#state-profile-modal').addClass('force-fade');
          window.$('#indicator-profile-modal').addClass('force-fade');

          // close if either is open, but don't close if select a state is open
          stateProfileCloseTimeoutRefs.current.push(setTimeout(() => {
            window.$('#state-profile-modal:visible').each(function() {
              window.$(this).foundation('close');
            });
          }, easeDurationMs*2));
          indicatorProfileCloseTimeoutRefs.current.push(setTimeout(() => {
            window.$('#indicator-profile-modal:visible').each(function() {
              window.$(this).foundation('close');
            });
          }, easeDurationMs*2));
      }
    }
  }, [
    allAvailableYears,
    dispatch,
    indicatorProfile.currentMetricId,
    loadComplete,
    location.search,
    selectedYear,
    stateProfile.currentStateAbbv,
    states,
    visibleMetrics
  ])

	if (!loadComplete) return null;
  return (
    <React.Fragment>
			<SelectAState />
			<StateProfile />
			<IndicatorProfile />
		</React.Fragment>
  );
};
