

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-foundation';
import { NavLink, useLocation } from 'react-router-dom';
import {
	getAllAvailableYears,
	getSelectedYear,
	getSelectedCompareYear,
	getAvailableYearsOfSameTypeAsSelected,
	getSelectedAvailableMetrics,
	getIfMetricsAreFiltered,
	getCompareMetricDeltaWarning,
} from '../../selectors';
import { changeSelectedYear, changeSelectedComparedYear } from '../../actions';
import MetricsDrawer from './MetricsDrawer';
import filterIcon from '../images/filter.svg';
import filterActiveIcon from '../images/filter-active.svg';
import filterDisabledIcon from '../images/filter-disabled.svg';
import modalCloseMobile from '../images/modal-close-mobile-white.svg';
import { getElectionType } from '../../bizUtils';

export default function NavFilterBar({ filterByIndicatorDisabled, compareYearsDisabled }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const allAvailableYears = useSelector(state => getAllAvailableYears(state));
	const selectedYear = useSelector(state => getSelectedYear(state));
	const selectedCompareYear = useSelector(state => getSelectedCompareYear(state));
	const areMetricsFiltered = useSelector(state => getIfMetricsAreFiltered(state));
	const selectedAvailableMetrics = useSelector(state => getSelectedAvailableMetrics(state));
	const availableYearsOfSameTypeAsSelected = useSelector(state => getAvailableYearsOfSameTypeAsSelected(state));
	const compareMetricDeltaWarning = useSelector(state => getCompareMetricDeltaWarning(state));
	const handleSelectedCompareYearChange = (clickedYear) => {
		selectedCompareYear === clickedYear
			? dispatch(changeSelectedComparedYear(null))
			: dispatch(changeSelectedComparedYear(clickedYear));
	};
	const handleSelectedYearChange = (clickedYear) => {
		dispatch(changeSelectedYear(clickedYear));
	};
	const handleCloseYearFilter = () => {
		window.$('#year-selection-dropdown').foundation('close');
	}
	const shortCompareYearText = selectedCompareYear ? `’${selectedCompareYear.slice(-2)}` : '';
	const shortYearText = `’${selectedYear.slice(-2)}`;

	const promptLine1 = 'How did each state perform in the last election? Explore';
	const promptLine2 = location.pathname.indexOf('map') !== -1
		? 'the data by selecting filters and interacting with the map.'
		: location.pathname.indexOf('rank') !== -1
			? 'the data by selecting filters and interacting with the state ranks.'
			: 'the data by selecting individual criteria, or indicators.'

	const secondPrompt = selectedAvailableMetrics.length === 1 && location.pathname.indexOf('rank') !== -1
		? 'The indicator percentages in this view are normalized across all states. See raw values in the map view.'
		: null;

	return (
		<div className='nav-filter-bar-wrapper'>
			<div className='nav-filter-bar'>
				<div className='nav-filter-bar_prompts'>
					<div>
						{promptLine1}
						<br />
						{promptLine2}
					</div>
					<div>{secondPrompt}</div>
				</div>
				<div className='nav-filter-bar_content'>
					<div className='nav-filter-bar_display-options'>
						<div className='nav-filter-bar_display-option'>
							<NavLink activeClassName='is_active' exact to='/data/map'>MAP</NavLink>
						</div>
						<div className='nav-filter-bar_display-option'>
							<NavLink activeClassName='is_active' exact to='/data/rank'>RANK</NavLink>
						</div>
						<div className='nav-filter-bar_display-option'>
							<NavLink activeClassName='is_active' exact to='/data/indicators'>INDICATORS</NavLink>
						</div>
					</div>
					<div
						className={
							selectedCompareYear && !compareYearsDisabled
								? 'nav-filter-bar_filter is-comparing'
								: 'nav-filter-bar_filter'
						}
					>
						<Button
							className='nav-filter-bar_year-filter-button'
							data-toggle='year-selection-dropdown'
						>
							<span className='is-not-small-screen'>
								{selectedCompareYear && !compareYearsDisabled ? `${selectedYear} vs ${selectedCompareYear}` : selectedYear}
							</span>
							<span className='is-small-screen'>
								{selectedCompareYear && !compareYearsDisabled ? `${shortYearText} vs ${shortCompareYearText}` : selectedYear}
							</span>
						</Button>
						<div
							className='dropdown-pane nav-filter-bar_year-filter'
							id='year-selection-dropdown'
							data-dropdown
							data-hover='true'
							data-hover-pane='true'
							data-alignment='center'
							data-hover-delay='0'
						>
							<div className='nav-filter-bar_year-filter-arrow'></div>
							<div className={
											compareYearsDisabled
												? 'nav-filter-bar_year-filter-contents is-short'
												: 'nav-filter-bar_year-filter-contents'
										}
							>
								<div className='nav-filter-bar_year-filter-contents_close'>
									<button aria-label='Close menu' type='button' onClick={handleCloseYearFilter}>
										<span aria-hidden='true'><img src={modalCloseMobile} alt='close' /></span>
									</button>
								</div>
								{
									compareYearsDisabled
										? null
										:
											(
												<div>
													Compare {selectedYear} to other {getElectionType(selectedYear).toLowerCase()} elections
													<div className='nav-filter-bar_choose-year'>
														{ availableYearsOfSameTypeAsSelected.map(year => 
																<Button
																	onClick={() =>handleSelectedCompareYearChange(year)}
																	className={year === selectedCompareYear ? 'is-selected' : null}
																	key={year}
																>
																	{year}
																</Button>
															)
														}
													</div>
												</div>
											)
								}
								<div>
									View another year
									<div className='nav-filter-bar_choose-year'>
										{ allAvailableYears.map(year => 
												<Button
													onClick={() => handleSelectedYearChange(year)}
													className={year === selectedYear ? 'is-selected' : null}
													key={year}
												>
													{year}
												</Button>
											)
										}
									</div>
								</div>
							</div>
						</div>
						<Button
							className={
								filterByIndicatorDisabled
									? 'nav-filter-bar_metric-filter-button is-disabled' 
									: areMetricsFiltered
										? 'nav-filter-bar_metric-filter-button is-filtered' 
										: 'nav-filter-bar_metric-filter-button'
							}
							disabled={filterByIndicatorDisabled}
							data-toggle='metrics-drawer'
						>
							<img
								alt='filter'
								src={filterByIndicatorDisabled ? filterDisabledIcon : areMetricsFiltered ? filterActiveIcon : filterIcon}
							/>
							Filter by Indicator
						</Button>
					</div>
				</div>
				<div className='nav-filter-bar_selected-indicators-description'>
					{
						filterByIndicatorDisabled
							? null
							: selectedAvailableMetrics.length === 0
								? '0 Indicators Selected'
								: !areMetricsFiltered
									? null
									: selectedAvailableMetrics.length === 1
										? `${selectedAvailableMetrics.length} Indicator Selected`
										: `${selectedAvailableMetrics.length} Indicators Selected`
					}
					<div className='nav-filter-bar_selected-indicators-warning'>
						{compareMetricDeltaWarning}
					</div>
				</div>
				<MetricsDrawer />
			</div>
		</div>
  );
}
