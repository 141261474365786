import React from 'react';
import { Button } from 'react-foundation';

export default function ErrorUnhandled() {
  return (
    <div className='app-failure is-without-description'>
      <div className='app-failure_title'>Something went wrong</div>
      <Button onClick={() => window.location.reload()} type='button' className='clear'>Please refresh</Button>
    </div>
  );
}
