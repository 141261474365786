import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	getVisibleMetrics,
	getSelectedMetrics,
	getVisibleMetricsGrouped,
	getSelectedYear,
	getSelectedCompareYear,
	getSelectedAvailableMetrics,
} from '../../selectors';
import { changeSelectedMetrics } from '../../actions';
import { Button } from 'react-foundation';
import isEqual from 'lodash/isEqual';
import filterIcon from '../images/filter.svg';
import modalCloseMobile from '../images/modal-close-mobile-white.svg';

export default function MetricsDrawer() {
	const dispatch = useDispatch();
	const isShowingScatterPlot = useSelector(state => state.isShowingScatterPlot);
	const metrics = useSelector(state => getVisibleMetrics(state));
	const groupedMetrics = useSelector(state => getVisibleMetricsGrouped(state));
	const selectedMetrics = useSelector(state => getSelectedMetrics(state));
	const selectedAvailableMetrics = useSelector(state => getSelectedAvailableMetrics(state));
	const selectedYear = useSelector(state => getSelectedYear(state));
	const selectedCompareYear = useSelector(state => getSelectedCompareYear(state));
	const handleCheckboxClick = (e) => {
		const metricName = e.target.value;
		let updatedSelectedMetrics = [...selectedMetrics];
		if (selectedAvailableMetrics.includes(metricName)) {
			if (
				isShowingScatterPlot &&
				selectedAvailableMetrics.length === 5
			) {
				const metric = metrics.find(m => m.name === metricName);
				window.$(`#tooltip-${metric.id}`).foundation('show');
				const tooltipId = window.$(`#tooltip-${metric.id}`).attr('data-toggle');

				// we add is-invisible to tooltips as we can't stop them from opening on click sometimes
				// and then add/remove it when necessary via js events directly
				// foundation still takes care of positioning
				window.$(`#${tooltipId}`).removeClass('is-invisible');
				return;
			}
			updatedSelectedMetrics = updatedSelectedMetrics.filter(m => m !== metricName);
		} else {
			updatedSelectedMetrics.push(metricName);
		}
		dispatch(changeSelectedMetrics(updatedSelectedMetrics));
	};
	const areAllSelected = () => {
		return isEqual(selectedAvailableMetrics.sort(), metrics.map(m => m.name).sort());
	};
	const areNoneSelected = () => {
		return isEqual(selectedAvailableMetrics, []);
	};
	const selectAll = () => {
		dispatch(changeSelectedMetrics([...metrics.map(m => m.name)]));
	};
	const selectNone = () => {
		dispatch(changeSelectedMetrics([]));
	};
	const handleClickAll = () => {
		if (areAllSelected()) {
			if (isShowingScatterPlot) {
				window.$(`#tooltip-all`).foundation('show');
				const tooltipId = window.$('#tooltip-all').attr('data-toggle');
				window.$(`#${tooltipId}`).removeClass('is-invisible');
				return;
			}
			else {
				selectNone();
				return;
			}
		}
		selectAll();
	};
	const handleClickNone = () => {
		if (isShowingScatterPlot) {
			window.$(`#tooltip-none`).foundation('show');
			const tooltipId = window.$('#tooltip-none').attr('data-toggle');
			window.$(`#${tooltipId}`).removeClass('is-invisible');
			return;
		}
		selectNone();
	};

	useEffect(() => {
		// delete old tooltip instances from last rendering
		window.$('.metrics-drawer_metrics_too-few-metrics-tooltip').remove();

		// bind even listener to readd is-visible when necessary (full explanation in comment where we remove class)
		setTimeout(() => {
			window.$('.metrics-drawer_metrics_too-few-metrics-tooltip-toggle')
				.each(function() {
					window.$(this).on('hide.zf.tooltip', function() {
						const tooltipeId = window.$(this).attr('data-toggle');
						window.$(`#${tooltipeId}`).addClass('is-invisible');
				})
			});
		});
	}, [])

	// on mobile, we are forcing the drawer to be full screen, like a modal
	// foundation isn't handling this as necessary (I'm not assigning blame! I'm using this as not intended)
	// so we must do some handling ourselves
	const handleClose = () => {
		window.$('#metrics-drawer').foundation('close');
		window.$('body').removeClass('is-off-canvas-open');
	}
  return (
		<div
			data-close-on-click='true'
			className='off-canvas position-right metrics-drawer'
			id='metrics-drawer'
			data-off-canvas
			data-content-scroll='false'
			content-scroll='true'
		>
			<button
				className='close-button metrics-drawer_close-button'
				aria-label='Close menu'
				type='button'
				data-close=''
				onClick={handleClose}
			>
				<span className='is-desktop' aria-hidden='true'>CLOSE</span>
				<span className='is-mobile' aria-hidden='true'><img src={modalCloseMobile} alt='close' /></span>
			</button>
			<div className='metrics-drawer_content'>
				<div className='metrics-drawer_title'>
					<img alt='filter' src={filterIcon} />
					Filter by Indicator
				</div>
				<div className='metrics-drawer_all-none'>
					<span>SELECT</span>
					<div
						data-tooltip='true'
						tabIndex='3'
						title='No fewer than 5 indicators can be selected to view the scatter plot.'
						data-hover-delay='100'
						data-disable-hover='true'
						data-disable-for-touch='true'
						data-click-open='false'
						data-position='bottom'
						data-alignment='right'
						id='tooltip-all'
						data-template-classes='metrics-drawer_metrics_too-few-metrics-tooltip is-invisible'
					>
						<Button
							className={areAllSelected() ? 'is-selected' : null}
							onClick={handleClickAll}
						>
							All
						</Button>
					</div>
					<div
						data-tooltip='true'
						tabIndex='3'
						title='No fewer than 5 indicators can be selected to view the scatter plot.'
						data-hover-delay='100'
						data-disable-hover='true'
						data-disable-for-touch='true'
						data-click-open='false'
						data-position='bottom'
						data-alignment='right'
						id='tooltip-none'
						data-template-classes='metrics-drawer_metrics_too-few-metrics-tooltip is-invisible'
					>
						<Button
							className={areNoneSelected() ? 'metrics-drawer_all-none_none is-selected' : 'metrics-drawer_all-none_none'}
							onClick={handleClickNone}
						>
							None
						</Button>
					</div>
				</div>
				<div className='metrics-drawer_metrics'>
					{
						groupedMetrics.filter(mg => mg.metrics.length > 0).map(metricGroup => {
							return (
								<React.Fragment key={metricGroup.id} >
									<div className='metrics-drawer_metrics_group-title'>
										{metricGroup.name}
									</div>
									<div className='metrics-drawer_metrics_group'>
										{
										metricGroup.metrics.map(metric => {
											const isMetricAvailableInYear = metric.yearsAvailable.includes(selectedYear);
											const isMetricAvailableInCompareYear = selectedCompareYear && metric.yearsAvailable.includes(selectedCompareYear);
											return (
													<div
														key={metric.id}
														data-tooltip='true'
														tabIndex='3'
														title='No fewer than 5 indicators can be selected to view the scatter plot.'
														data-disable-hover='true'
														data-disable-for-touch='true'
														data-click-open='false'
														data-position='bottom'
														data-alignment='center'
														className='metrics-drawer_metrics_too-few-metrics-tooltip-toggle'
														id={`tooltip-${metric.id}`}
														data-template-classes='metrics-drawer_metrics_too-few-metrics-tooltip is-invisible'
													>
														<div className='checkbox secondary checkbox-circle metrics-drawer_metrics_group_metric'>
															<input
																checked={selectedAvailableMetrics.includes(metric.name) ? true : false}
																type='checkbox'
																name='metric'
																disabled={!isMetricAvailableInYear && !isMetricAvailableInCompareYear}
																value={metric.name}
																onChange={handleCheckboxClick}
															/>
															<label htmlFor={metric.id}>{metric.displayName}</label>
														</div>
													</div>
											)
										})
										}
									</div>
								</React.Fragment>
							)
						})
					}
				</div>
				<div className='metrics-drawer_learn-more'>
					<Link onClick={handleClose} to='/data/indicators'>LEARN MORE ABOUT INDICATORS</Link>
				</div>
			</div>
		</div>
  );
}
