import { 
  FETCH_RAW_DATA_SUCCESS,
  LOAD_COMPLETE,
  ENRICH_METRICS_SUCCESS,
  NORMALIZE_DATA_SUCCESS,
  CHANGE_SELECTED_YEAR,
  CHANGE_SELECTED_COMPARE_YEAR,
  CHANGE_SELECTED_METRICS,
  ENRICH_DATA_SUCCESS,
  SET_STATE_PROFILE,
  SET_INDICATOR_PROFILE,
  ENRICH_STATES_SUCCESS,
  LOAD_FAILED,
  SET_IS_SHOWING_SCATTER_PLOT,
  FETCH_FRONT_PAGE_SUCCESS,
  UPDATE_HOVERED_STATE_ABBV,
} from '../constants/action-types';
import states from '../data/states.json';
import stateShapes from '../data/stateShapes.json';
import metrics from '../data/metrics.json';
import metricGroups from '../data/metricGroups.json';

const initialStates = {
  loadComplete: false,
  loadFailed: false,
  states,
  stateShapes,
  metrics,
  metricGroups,
  hoveredStateAbbv: null,
  isShowingScatterPlot: false,
  data: {
    raw: null,
    enriched: null,
    normalized: null,
  },
  stateProfile: {
    currentStateAbbv: null,
  },
  indicatorProfile: {
    currentMetricId: null,
  },
  selection: {
    year: null,
    compareYear: null,
    metrics: metrics.filter(m => !m.hidden).map(m => m.name),
  },
  frontPage: {
    spotlight: '',
    update1: {
      date: '',
      author: '',
      title: '',
      subtitle: '',
      articleUrl: '',
      imageUrl: '',
      imageAlt: '',
    },
    update2: {
      date: '',
      author: '',
      title: '',
      subtitle: '',
      articleUrl: '',
      imageUrl: '',
      imageAlt: '',
    },
  },
};

const rootReducer = (state = initialStates, action) => {
  switch (action.type) {
    case FETCH_RAW_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          raw: { ...action.payload },
        },
      };
    case FETCH_FRONT_PAGE_SUCCESS:
      return {
        ...state,
        frontPage: { ...action.payload },
      };
    case ENRICH_STATES_SUCCESS:
      return {
        ...state,
        states: [ ...action.payload ],
      };
    case ENRICH_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          enriched: { ...action.payload },
        },
      };
    case NORMALIZE_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          normalized: { ...action.payload },
        },
      };
    case ENRICH_METRICS_SUCCESS:
      return {
        ...state,
        metrics: [ ...action.payload ],
      };
    case LOAD_COMPLETE:
      return { ...state, loadComplete: true };
    case LOAD_FAILED:
      return { ...state, loadFailed: true };
    case CHANGE_SELECTED_YEAR:
      return { ...state, selection: { ...state.selection, year: action.payload, compareYear: null } };
    case CHANGE_SELECTED_COMPARE_YEAR:
      return { ...state, selection: { ...state.selection, compareYear: action.payload } };
    case CHANGE_SELECTED_METRICS:
      return { ...state, selection: { ...state.selection, metrics: action.payload } };
    case SET_STATE_PROFILE:
      return { ...state, stateProfile: { currentStateAbbv: action.payload } };
    case SET_INDICATOR_PROFILE:
      return { ...state, indicatorProfile: { currentMetricId: action.payload } };
    case SET_IS_SHOWING_SCATTER_PLOT:
      return { ...state, isShowingScatterPlot: action.payload };
    case UPDATE_HOVERED_STATE_ABBV:
      return { ...state, hoveredStateAbbv: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
