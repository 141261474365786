import has from 'lodash/has';
import styleVariables from './components/scss/Variables.scss';

export const InvalidValues = Object.freeze({
	NA: -1,
	INC: -2,
});

export const getKeyByValue = (value, object) => {
	const index = Object.values(object).indexOf(value);
	if (index === -1) return null;
	return Object.keys(object)[index];
};

export const MetricTypes = Object.freeze({
	Numeric: 'numeric',
	Boolean: 'boolean',
	Enum: 'enum',
});

export const MetricDirections = Object.freeze({
	Reverse: 'reverse',
});

export const getColorFromValue = (value, treatMissingAsNa) => {
	// using random mapping of range to color right now until defixzne what discretized colors map to
	if (value === InvalidValues.NA) return styleVariables.notApplicable;
	if (value === InvalidValues.INC) return treatMissingAsNa ? styleVariables.notApplicable : styleVariables.missing;
	if (value < 0) {
		console.error(`Encountered unexpected value in getColorFromValue of ${value}`);
		return styleVariables.missing;
	}
	if (value < .2) return styleVariables.worst;
	if (value < .4) return styleVariables.bad;
	if (value < .6) return styleVariables.neutral;
	if (value < .8) return styleVariables.good;
	return styleVariables.best;
};

export const getStateNormalizedAverageForYear = (
	allNormalizedData,
	stateAbbv,
	selectedMetricNames,
	metrics,
	year
) => {
	let metricCount = 0;
	let naCount = 0;
	let incompleteCount = 0;
	let metricNormalizedValueSum = 0;
	selectedMetricNames.forEach(metricName => {
		const metric = metrics.find(m => m.name === metricName);
		if (
				(!metric.hidden && metric.yearsAvailable.includes(year.toString())) ||
				metric.calculated
		) {
			const dataForStateAndYear = allNormalizedData[year].find(stateYearData => stateYearData.state_abbv === stateAbbv);

			if (has(dataForStateAndYear, metricName)) {
				if (isValidNormalizedValue(dataForStateAndYear[metricName])) {
					metricCount++;
					metricNormalizedValueSum += dataForStateAndYear[metricName];
				} else if (dataForStateAndYear[metricName] === InvalidValues.INC) {
					incompleteCount++;
				} else if (dataForStateAndYear[metricName] === InvalidValues.NA) {
					naCount++;
				} else {
					console.error(
						`Encountered unexpected invalid value of ${dataForStateAndYear[metricName]} for state ${stateAbbv} and metric ${metricName} in year ${year}`
					);
				}
			}
		}
	});
	if (metricCount > 0) return metricNormalizedValueSum / metricCount;
	if (naCount === 0 && incompleteCount > 0) return InvalidValues.INC;
	if (naCount > 0 && incompleteCount === 0) return InvalidValues.NA;

	// INC is more important to show than NA
	if (naCount > 0 && incompleteCount > 0) return InvalidValues.INC;
	return InvalidValues.NA;
};

/// <summary>
/// Takes the raw data and populates both a display value and short display value as well as a "calculatedValueDenominator".
/// The calculatedValueDenominator is used for metrics that are aggregates of other metrics, termed "calculated" (at the 
/// time of writing this, that is only one metric). However, not all metrics exist in all years, so for a calculated metric, 
/// we need to know how many metrics actually comprise that new metric for a given year.
/// Note that this method does not do anything with normalized data currently.
/// </summary>
export const enrichData = (allRawData, metrics) => {
	const enrichedAllRawData = {};

		// iterate through each year
	allRawData.forEach(yearData => {
		const enrichedDataForYear = [];

		// iterate through each state in that year and apply enrichment logic for the given metric
		yearData.rawData.forEach(rawDataForYearAndState => {
			const enrichedDataForStateAndYear = {state_abbv: rawDataForYearAndState.state_abbv};

			// iterate through metrics
			metrics.forEach((metric) => {

				// only enrich data that exists for that year (if it exists for one state, it exists for all in that year)
				if (has(rawDataForYearAndState, metric.name) || metric.calculated) {

					let value, calculatedValueDenominator;
					if (metric.calculated) {
						({value, calculatedValueDenominator} = getCalculatedMetric(rawDataForYearAndState, yearData.year, metric, metrics));
					} else {
						value = rawDataForYearAndState[metric.name];
					}

					const displayValue = getDisplayValue(metric, value, calculatedValueDenominator);
					const shortDisplayValue = getDisplayValue(metric, value, calculatedValueDenominator, true);
					enrichedDataForStateAndYear[metric.name] = { value, displayValue, shortDisplayValue, calculatedValueDenominator };
				}
			});
			enrichedDataForYear.push(enrichedDataForStateAndYear);
		})
		enrichedAllRawData[yearData.year] = enrichedDataForYear;
	});
	return enrichedAllRawData;
};

export const invalidValueDecimalToLongName = (decimalValue) => {
	const shortValue = getKeyByValue(decimalValue, InvalidValues);
	switch (shortValue) {
		case 'NA':
			return 'N/A';
		case 'INC':
			return 'Incomplete Data';
		default:
			return '';
	}
}

export const getDisplayValue = (metric, value, calculatedValueDenominator, isShort = false) => {
	if (isNaN(value)) {
		const invalidValueType = getInvalidValueTypeFromNanValue(value);
		if (invalidValueType === InvalidValues.INC) return 'Incomplete Data';
		return 'N/A'
	}
	if (!metric) {
		// assume function is being used to format percentage
		return isShort ? `${Math.round(value * 100)}%` : `${Math.round(value * 10000)/100}%`;
	}
	switch (metric.type) {
		case MetricTypes.Boolean:
			return value === 1 ? 'Yes' : 'No';
		case MetricTypes.Enum:
			return has(metric.values, value.toString()) ? metric.values[value.toString()]: 'Unknown';
		case MetricTypes.Numeric:
			if (!metric.notPercent) {
				return isShort ? `${Math.round(value * 100)}%` : `${Math.round(value * 10000)/100}%`;
			} else {
				switch (metric.id) {
					case 'DA':
						return `${Math.round(value * 100)} pp`;
					case 'WTV':
						return isShort ? `${Math.round(value * 10)/10}` : `${Math.round(value * 10)/10} min`;
					case 'OLT':
						return isShort ? Math.round(value * 10)/10: `${value} out of ${calculatedValueDenominator}`;
					default:
						console.error(`Encountered unexpected metric that is numeric and notPercent: ${metric.name}`);
						return '';
				}
			}
		default:
			console.error(`Encountered unexpected data type of ${metric.type} for metric ${metric.name}`);
			return '';
	}
}
const getInvalidValueTypeFromNanValue = (value) => {
	if (value === 'NA') return InvalidValues.NA;
	if (value === 'INC') return InvalidValues.INC;
}

export const isValidNormalizedValue = (value) => {
	return !isNaN(value) && value >= 0;
};

export const isValidRawValue = (value) => {
	return !isNaN(value) && value !== '';
};

export const getMetricYearsAvailable = (allRawData, metric) => {
	const yearsAvailable = [];
	allRawData.forEach(yearData => {
		// if any state for this year has a valid value for the metric
		if (yearData.rawData.find(s => has(s, metric.name) && isValidRawValue(s[metric.name])) || metric.calculated) {
			yearsAvailable.push(yearData.year);
		}
	});
	return yearsAvailable;
};

export const warnOnPotentialInvalidRawData = (allRawData, metrics) => {
	if (!isRunningInDevMode()) {
		return;
	}
	for (const yearData of allRawData) {
		for (const metric of metrics) {
			// look for -2 and -1 in the raw data. These are the numeric representations of INC and NA
			// However, raw data should use strings for these values, not numbers, while normalized data should use numbers
			// Note that -2 and -1 may be correct, so this error may be a red herring in raw data, which can be negative (unlike normalized data, which should not be negative)
			const firstPotentiallyConfusedValue = yearData.rawData.find(s => has(s, metric.name) && [-2, -1].includes(s[metric.name]));
			if (firstPotentiallyConfusedValue) {
				console.warn(`[RAW DATA] Encountered potentially invalid value of ${firstPotentiallyConfusedValue[metric.name]} for metric ${metric.name} in year ${yearData.year}. NA and INC should be represented as strings in raw data, not numbers.`);
				continue;
			}

			// look for years that have the metric column but no valid values - the data likely was improperly formatted
			if (metric.yearsAvailable.includes(yearData.year)) {
				continue;
			}
			const firstInvalidValue = yearData.rawData.find(s => has(s, metric.name) && !['INC', 'NA'].includes(s[metric.name]));
			if (firstInvalidValue) {
				console.warn(`[RAW DATA] Encountered invalid value of ${firstInvalidValue[metric.name]} for metric ${metric.name} in year ${yearData.year}`);
				continue;
			}
		}
		// look for columns that do not match any metrics - the metric name likely has a typo
		for (const column of Object.keys(yearData.rawData[0])) {
			if (['state_abbv', 'state_fips', 'Index', 'year', 'index'].includes(column)) {
				// these columns are sometimes included for EPI lab purposes only
				continue;
			}
			if (!metrics.find(m => m.name === column)) {
				console.warn(`[RAW DATA] Encountered unexpected column name of ${column} in year ${yearData.year}`);
			}
		};
	}
}

export const warnOnPotentialInvalidNormalizedData = (allNormalizedData, metrics) => {
	if (!isRunningInDevMode()) {
		return;
	}
	const alreadyWarnedMissingYearAndMetric = [];
	const alreadyWarnedInvalidYearAndMetric = [];
	for (const year of Object.keys(allNormalizedData)) {
		const yearData = allNormalizedData[year];
		for (const stateYearData of yearData) {
			const stateAbbv = stateYearData.state_abbv;

			// look for improperly formatted values
			for (const column of Object.keys(stateYearData)) {
				const columnValue = stateYearData[column];
				if (!['state_abbv'].includes(column) && isNaN(parseFloat(columnValue))) {
					if (alreadyWarnedInvalidYearAndMetric.find(m => m.year === year && m.metric === column)) {
						continue;
					}
					alreadyWarnedInvalidYearAndMetric.push({year, metric: column});
					console.warn(`[NORMALIZED DATA] Encountered unexpected value of ${columnValue} for column ${column} in year ${year} and state ${stateAbbv}`);
				}
			}

			// look for missing values
			for (const metric of metrics) {
				if (!metric.hidden && metric.yearsAvailable.includes(year) && !has(stateYearData, metric.name)) {
					if (alreadyWarnedMissingYearAndMetric.find(m => m.year === year && m.metric === metric.name)) {
						continue;
					}
					alreadyWarnedMissingYearAndMetric.push({year, metric: metric.name});
					console.warn(`[NORMALIZED DATA] Encountered missing value for metric ${metric.name} in year ${year}`);
					continue;
				}
			}

			// look for columns that do not match any metrics - the metric name likely has a typo
			for (const column of Object.keys(stateYearData)) {
				if (['state_abbv', 'state_fips', 'Index', 'year', 'index'].includes(column)) {
					continue;
				}
				if (!metrics.find(m => m.name === column)) {
					console.warn(`[NORMALIZED DATA] Encountered unexpected column name of ${column} in year ${year}`);
				}
			};
		}
	}
}

export const electionTypes = Object.freeze({
	presidential: 'Presidential',
	midterm: 'Midterm',
});

export const getElectionType = (year) => {
	if (parseInt(year) % 4 === 0) return electionTypes.presidential;
	return electionTypes.midterm;
}

// this method is built generically but at hte time of writing this, only one calculation
// uses it. It averages the values of multiple metrics for some state and year, only if
// for the "source" metrics that are available in that year.
// it assumes the source metrics all have the range, which is a dangerous assumption if this
// is extended in the future.
const getCalculatedMetric = (stateYearData, year, metric, allMetrics) => {
	const sourceValues = [];
	const sourceColumns = metric.sourceColumns;
	sourceColumns.forEach(sourceMetricName => {
		const sourceMetric = allMetrics.find(m => m.name === sourceMetricName);
		if (sourceMetric.yearsAvailable.includes(year)) {
			// we only consider the value if the state provided it.
			// in other words, we don't penalize a state in a calculated metric if they didn't provide a
			// source metric value
			if(isValidRawValue(stateYearData[sourceMetric.name])) {
				sourceValues.push(parseFloat(stateYearData[sourceMetric.name]));
			}
		}
	});
	const sourceValueSum = sourceValues.reduce((sum, sourceValue) => sum += sourceValue);
	return {
		value: sourceValueSum,
		calculatedValueDenominator: sourceValues.length
	};
}

export const getNationwideAverageDisplayForMetricAndYear = (
	metric,
	normalizedDataByStateAndMetric,
	enrichedDataByStateAndMetric,
	year,
	aggregateContext = false,
) => {
	if (!metric.yearsAvailable.includes(year)) {
		return {
			percentageAsDecimal: undefined,
			value: undefined,
			text: '',
		};
	}

	let average;
	const validNormalizedDataForAllStates = Object.values(normalizedDataByStateAndMetric[year][metric.name])
		.filter(v => isValidNormalizedValue(v));
	if (metric.id === 'OLT') {
		if (!validNormalizedDataForAllStates || validNormalizedDataForAllStates.length === 0) {
			return {
				percentageAsDecimal: 0,
				value: '0%',
				text: 'of Possible Look-Up Tools'
			}
		}
		average = validNormalizedDataForAllStates.reduce((sum, v) => sum + v) / validNormalizedDataForAllStates.length;
		return {
			percentageAsDecimal: average,
			value: getDisplayValue(null, average, null, true),
			text: 'of Possible Look-Up Tools'
		}
	}

	const validEnrichedDataForAllStates = Object.values(enrichedDataByStateAndMetric[year][metric.name])
		.map(s => s.value)
		.filter(v => isValidRawValue(v));

	switch (metric.type) {
		case MetricTypes.Numeric:
			average = validEnrichedDataForAllStates.reduce((sum, v) => sum + v) / validEnrichedDataForAllStates.length;
			return {
				percentageAsDecimal: average,
				value: getDisplayValue(metric, average, null, false),
				text: aggregateContext ? 'Indicator Average' : 'Nationwide Average'
			}
		case MetricTypes.Enum:
		case MetricTypes.Boolean:
			if (!validNormalizedDataForAllStates || validNormalizedDataForAllStates.length === 0) {
				return {
					percentageAsDecimal: 0,
					value: '0%',
					text: 'of 0 states'
				}
			}
			const countAcrossStates = validNormalizedDataForAllStates.filter(v => v === 1).length;
			return {
				percentageAsDecimal: countAcrossStates / validNormalizedDataForAllStates.length,
				value: countAcrossStates,
				// text: `of ${validNormalizedDataForAllStates.length} states`
				text: `of 51 states`
			}
		default:
			console.error(`Unknown type of ${metric.type} encountered in getNationwideAverageDisplayForMetricAndYear`);
			return {
				percentageAsDecimal: undefined,
				value: undefined,
				text: '',
			};
	}
}

const isRunningInDevMode = () => {
	return process.env.NODE_ENV === 'development';
}