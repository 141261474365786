import React from 'react';
import { Link } from 'react-router-dom';

export default function Error404() {
  return (
    <div className='app-failure'>
      <div className='app-failure_title'>404 not found</div>
      <div className='app-failure_description'>
        The page you are looking for doesn<span className='apostraphe'>’</span>t exist.
      </div>
      <Link to='/'>Back to home</Link>
    </div>
  );
}
