import React, { useEffect } from 'react';
import { useFoundation } from '../../../utils';
import { useSelector, useDispatch }  from 'react-redux';
import { showStateProfile } from '../../../actions';
import {
	getNormalizedAverageByStateByYearSelectedMetrics,
	getSelectedYear,
	getStates,
	getAvailableYearsOfSameTypeAsSelected,
} from '../../../selectors';
import { isValidNormalizedValue, InvalidValues, getKeyByValue } from '../../../bizUtils';
import debounce from 'lodash/debounce';

const renderSectionWithStates = (
	section,
	statesInSection,
	averageByStateInSelectedYear,
	states,
	index,
	setCurrentHoverState,
	currentHoverState,
	availableYearsOfSameTypeAsSelected,
	normalizedAverageByStateByYear,
	dispatch
) => {
	return (
		<div data-sticky-container=''>
			<div className='rank_section' id={`rank_section-${index}`}>
				<div
					className={
						section.isFirst
							? 'sticky rank_section_title is-first'
							: 'sticky rank_section_title'
					}
					data-sticky='' data-options='marginTop:0;'
					data-anchor={`rank_section-${index}`}
					data-sticky-on='small'
					id={`rank_section-${index}_title`}
				>
					<div className={currentHoverState === null ? 'rank_section_title_overlay' : 'rank_section_title_overlay is-active'} />
					<span className='rank_section_title_percentage'>
						{section.title} {section.titleText ? <span>{section.titleText}</span> : null }
					</span>

					{/* on IE, the overlay is considered part of the flex distribution. So instead using absolute positioning
					and adding placeholder to force buttons to right */}
					<span className='is-desktop'></span>
					<span className='rank_section_title_buttons is-desktop' data-magellan=''>
						<a href='#rank-top' className='clear'>JUMP TO TOP</a>
						<a href='#rank-bottom' className='clear'>JUMP TO BOTTOM</a>
					</span>
				</div>
				<div className='rank_section_states'>
					{statesInSection.map(stateAbbv => {
						const stateInfo = states.find(stateInfo => stateInfo.abbv === stateAbbv);
						let otherYearDetails = [];
						const width =
							isValidNormalizedValue(averageByStateInSelectedYear[stateAbbv])
								? `${averageByStateInSelectedYear[stateAbbv]*100}%`
								: 0;
						availableYearsOfSameTypeAsSelected.forEach(otherYear => {
							const otherYearValue = normalizedAverageByStateByYear[otherYear][stateAbbv];
							if (isValidNormalizedValue(otherYearValue)) {
								const otherYearRank = Object.keys(normalizedAverageByStateByYear[otherYear]).indexOf(stateAbbv) + 1;
								otherYearDetails.push(`${otherYear}: ${Math.round(otherYearValue*100)}% (#${otherYearRank})`);
							}
						});
						return (
							<div
								key={stateAbbv}
								className={
									currentHoverState === stateAbbv
										? 'rank_section_state is-hovered'
										: currentHoverState === null
											? 'rank_section_state'
											: 'rank_section_state other-is-hovered'
									}
								onMouseEnter={() => setCurrentHoverState(stateAbbv, null)}
								onMouseLeave={() => setCurrentHoverState(null, stateAbbv)}
							>
								<div className='rank_section_state_bar-and-rank'>
									<div className='rank_section_state_rank'>
										{Object.keys(averageByStateInSelectedYear).indexOf(stateAbbv) + 1}
									</div>
									<div
										className={ width === 0 || width === '0%' ? 'rank_section_state_bar is-zero-width' : 'rank_section_state_bar' }
										style={{ width: width === 0 ? '8px' : width }}
										onClick={() => dispatch(showStateProfile(stateAbbv))}
									>
										{stateInfo.name}
									</div>
									<div
										className={ width === 0 || width === '0%' ? 'rank_section_state_bar-short is-zero-width' : 'rank_section_state_bar-short' }
										style={{ width: width === 0 ? '8px' : width }}
										onClick={() => dispatch(showStateProfile(stateAbbv))}
									>
										{stateInfo.abbv}
									</div>
								</div>
								<div className='rank_section_state_percentage'>
									{
										isValidNormalizedValue(averageByStateInSelectedYear[stateInfo.abbv])
											? `${Math.round(averageByStateInSelectedYear[stateInfo.abbv]*100)}%`
											: getKeyByValue(averageByStateInSelectedYear[stateInfo.abbv], InvalidValues)
									}
								</div>
								<div className='rank_section_state_other-years is-desktop'>
									<div>
										{otherYearDetails.map((detail, detailIndex) => <div key={detailIndex}>{detail}</div>)}
									</div>
								</div>
							</div>
						)})
					}
				</div>
			</div>
		</div>
 );
};

const renderSectionWithoutStates = (section) => {
  return (
		<div className='rank_section'>
			<div className='rank_section_no-states'>
				<div className='rank_section_no-states-title'>
					{section.title} {section.titleText ? <span>{section.titleText}</span> : null }
				</div>
				<div className='rank_section_no-states-details'>
					N/A. No states scored&nbsp;
					{
						section.titleText
							? `${section.title} ${section.titleText}`
							: `between ${section.title}`
					}
					&nbsp;for this set of indicators.
				</div>
			</div>
		</div>
  );
};

export default function RankSection({ section, index, statesInSection, setCurrentHoverState, currentHoverState }) {
	useFoundation();
	const dispatch = useDispatch();
	const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearSelectedMetrics(state));
	const availableYearsOfSameTypeAsSelected = useSelector(state => getAvailableYearsOfSameTypeAsSelected(state));
  const states = useSelector(state => getStates(state));
  const selectedYear = useSelector(state => getSelectedYear(state));
	const averageByStateInSelectedYear = normalizedAverageByStateByYear[selectedYear];

	const forceStickyRecalc = () => {
		const stickySections = window.$(`.sticky`);

		// are elements still on page?
		if (stickySections.length > 0) {
			(window.$(`.sticky`).foundation('_calc', true))
		}
	}
	window.$(window).on('resize', debounce(forceStickyRecalc, 150));
	useEffect(() => {
    window.jQuery(document).ready($ => {
			($(document).foundation());
      setTimeout(forceStickyRecalc);
    });
  }, [averageByStateInSelectedYear]);

	if (statesInSection.length > 0) {
		return renderSectionWithStates(
			section,
			statesInSection,
			averageByStateInSelectedYear,
			states,
			index,
			setCurrentHoverState,
			currentHoverState,
			availableYearsOfSameTypeAsSelected,
			normalizedAverageByStateByYear,
			dispatch
		);
	} else {
		return renderSectionWithoutStates(section);
	}
}
