import React from 'react';
import { Link } from 'react-router-dom';
import twitterIcon from './images/twitter-white.svg';
import facebookIcon from './images/facebook-white.svg';
import instagramIcon from './images/instagram-white.svg';
import mediumIcon from './images/medium-white.svg';
import linkedInIcon from './images/linkedIn-white.svg';
import linkedInHoverIcon from './images/linkedIn-pink.svg';
import twitterHoverIcon from './images/twitter-pink-on-blue.svg';
import facebookHoverIcon from './images/facebook-pink-on-blue.svg';
import instagramHoverIcon from './images/instagram-pink-on-blue.svg';
import mediumHoverIcon from './images/medium-pink.svg'
import logo from './images/logo.svg';

export default function Footer() {
  return (
		<div className='footer'>
				<div className='footer_left'>
					<div className='footer_title'>
						<Link to='/'>
							<div>
								<img alt='logo' src={logo} />
							</div>
							<div className='footer_title_text'>
								Elections
								<br />
								Performance Index
							</div>
						</Link>
					</div>
					<div className='footer_attribution is-desktop'>
						<a target='_blank' rel='noreferrer noopener' href='https://electionlab.mit.edu/'>
							A PROJECT OF MIT ELECTION DATA & SCIENCE LAB
						</a>
						<div className='footer_attribution_copyright'>
							©{new Date().getFullYear()} Massachusetts Institute of Technology Election Data + Science Lab
							<span className='footer_attribution_copyright_spacer'>|</span>
							<a target='_blank' rel='noreferrer noopener' href='https://accessibility.mit.edu/'>
								Accessibility
							</a>
						</div>
					</div>
				</div>
				<div className='footer_right'>
					<div className='footer_address'>
						77 Massachusetts Avenue
						<br />
						Cambridge, MA 02139
					</div>
					<div className='footer_contact'>
						(617) 253-3127
						<br />
						<a target='_blank' rel='noreferrer noopener' href='mailto:mitelectionlab@mit.edu'>mitelectionlab@mit.edu</a>
					</div>
					<div className='footer_social'>
						<a target='_blank' rel='noreferrer noopener' href='https://www.twitter.com/mitelectionlab'>
							<img className='is-not-hover' alt='twitter' src={twitterIcon} />
							<img className='is-hover' alt='twitter' src={twitterHoverIcon} />
						</a>
						<a target='_blank' rel='noreferrer noopener' href='https://www.facebook.com/mitelectionlab'>
							<img className='is-not-hover' alt='facebook' src={facebookIcon} />
							<img className='is-hover' alt='facebook' src={facebookHoverIcon} />
						</a>
						<a target='_blank' rel='noreferrer noopener' href='https://www.instagram.com/mitelectionlab'>
							<img className='is-not-hover' alt='instagram' src={instagramIcon} />
							<img className='is-hover' alt='instagram' src={instagramHoverIcon} />
						</a>
						<a target='_blank' rel='noreferrer noopener' href='https://medium.com/mit-election-lab'>
							<img className='is-not-hover' alt='medium' src={mediumIcon} />
							<img className='is-hover' alt='medium' src={mediumHoverIcon} />
						</a>
						<a target='_blank' rel='noreferrer noopener' href='https://www.linkedin.com/company/mit-election-data-and-science-lab'>
							<img className='is-not-hover' alt='linkedIn' src={linkedInIcon} />
							<img className='is-hover' alt='linkedIn' src={linkedInHoverIcon} />
						</a>
					</div>
					<div className='footer_attribution is-mobile'>
						<a target='_blank' rel='noreferrer noopener' href='https://electionlab.mit.edu/'>
							A PROJECT OF MIT ELECTION DATA & SCIENCE LAB
						</a>
						<div className='footer_attribution_copyright'>
							©{new Date().getFullYear()} Massachusetts Institute of Technology Election Data + Science Lab
							<span className='footer_attribution_copyright_spacer'>|</span>
							<a target='_blank' rel='noreferrer noopener' href='https://accessibility.mit.edu/'>
								Accessibility
							</a>
						</div>
					</div>
				</div>
		</div>
  );
}
