import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch }  from 'react-redux';
import {
	getStates,
	getStateProfile,
	getSelectedYear,
	getAllAvailableYears,
	getMetrics,
	getVisibleMetricsGrouped,
	getNormalizedAverageByStateByYearAllMetrics,
	getAvailableYearsOfSameTypeAsSelected,
	getNormalizedDataByYearByMetric,
	getEnrichedDataByYear,
} from '../../selectors';
import StateProfileIndicator from './StateProfileIndicator';
import StateProfileIndicatorNotAvailable from './StateProfileIndicatorNotAvailable';
import { changeSelectedYear, showIndicatorProfile, closeStateProfile } from '../../actions';
import { useFoundation } from '../../utils';
import closeIcon from '../images/modal-close.svg';
import closeHoverIcon from '../images/modal-close-hover.svg';
import closeMobileIcon from '../images/modal-close-mobile-black.svg';
import { isValidNormalizedValue } from '../../bizUtils';
import { select, scaleTime, extent, scaleLinear, max } from 'd3';
import twitterIcon from '../images/twitter-grey.svg';
import twitterHoverIcon from '../images/twitter-pink-on-white.svg';
import facebookIcon from '../images/facebook-grey.svg';
import facebookHoverIcon from '../images/facebook-pink-on-white.svg';
import emailIcon from '../images/email-grey.svg';
import emailHoverIcon from '../images/email-pink.svg';
import linkIcon from '../images/link-grey.svg';
import linkHoverIcon from '../images/link-pink.svg';
import previousArrow from '../images/previous-arrow-small.svg';
import downArrow from '../images/down-arrow-black.svg';
import upArrow from '../images/up-arrow-black.svg';
import get from 'lodash/get';
import has from 'lodash/has';

// The interaction between jquery and react is non-ideal here as the component always needs to exist to be opened by 
// jquery, but the data may not exist if no state is provided into react state, so null protection is required throughout
// and then we need to protect as things change when years change and react lifecycle updates datapoints in parts
export default function StateProfile() {
	useFoundation();
	const dispatch = useDispatch();

	// pull data from redux
	const metrics = useSelector(state => getMetrics(state));
	const groupedMetrics = useSelector(state => getVisibleMetricsGrouped(state));
	const states = useSelector(state => getStates(state));
	const selectedYear = useSelector(state => getSelectedYear(state));
	const stateProfile = useSelector(state => getStateProfile(state));
	const allAvailableYears = useSelector(state => getAllAvailableYears(state));
	const normalizedAverageByStateByYear = useSelector(state => getNormalizedAverageByStateByYearAllMetrics(state));
	const averageByState = normalizedAverageByStateByYear[selectedYear];
	const availableYearsOfSameTypeAsSelected = useSelector(state => getAvailableYearsOfSameTypeAsSelected(state));
	const normalizedDataByStateAndMetric = useSelector(state => getNormalizedDataByYearByMetric(state));
	const enrichedDataByYear = useSelector(state => getEnrichedDataByYear(state));
	const currentUrlEncoded = encodeURIComponent(window.location.href);
	const currentUrl = window.location.href;
	const [justCopied, setJustCopied] = useState(false);
	const [indicatorComponentHeights, setIndicatorComponentHeights] = useState({
		value: groupedMetrics.reduce((grouped, gm) => ({ ...grouped, [gm.id]: { none: 0 } }), {}),
		description: groupedMetrics.reduce((grouped, gm) => ({ ...grouped, [gm.id]: { none: 0 } }), {}),
		name: groupedMetrics.reduce((grouped, gm) => ({ ...grouped, [gm.id]: { none: 0 } }), {}),
	});
	const handleCopyToClipboard = () => {
		setJustCopied(true);
		setTimeout(() => setJustCopied(false), 1500);
	}

	const handleGoToIndicatorProfile = (metricId) => {
		dispatch(showIndicatorProfile(metricId));
	}

	const handleClickViewAnotherState = () => {
		window.$('#select-state-modal').foundation('open');
		setTimeout(() => dispatch(closeStateProfile()), 300);
	}

	const handleClose = () => {
		dispatch(closeStateProfile());
	}

	// store state for things that matter which state is selected, to properly reload in react lifecycle as
	// jquery opens and closes component
	const [state, setState] = useState(null);
	const [shareContent, setShareContent] = useState({});
	const initialStateData = {
		rank: 0,
		average: 0,
	};
	const [stateData, setStateData] = useState(initialStateData);
	const [allYearsOfTypeData, setAllYearsOfTypeData] = useState(null);
	const handleSelectedYearChange = (clickedYear) => {
		dispatch(changeSelectedYear(clickedYear));
	};

	// show modal and update path when user clicks on some link to open modal (which updates global redux store)
	useEffect(() => {
		if (stateProfile.currentStateAbbv) {
			setState(states.find(s => s.abbv === stateProfile.currentStateAbbv));
		}
	}, [states, stateProfile]);

	// set data when state or year changes
	useEffect(() => {
		const getEnrichedFeaturedIndicators = (state, enrichedDataByYear, metrics, availableYearsOfSameTypeAsSelected) => {
			const metricNames = state.featuredIndicators ? state.featuredIndicators : [];
			const enrichedIndicators = metricNames.filter(name => {
					const metric = metrics.find(m => m.name === name);
					return metric && (metric.yearsAvailable.includes(selectedYear) || metric.calculated);
				})
				.map(name => {
					const metric = metrics.find(m => m.name === name);
					const enrichedDataForStateAndYear =
						enrichedDataByYear[selectedYear].find(s => s.state_abbv === state.abbv);

					// compare this indicator to other years for this state
					const allYearsOfSameType = availableYearsOfSameTypeAsSelected.concat(selectedYear).sort();
					const selectedYearIndex = allYearsOfSameType.indexOf(selectedYear);
					let deltaFromPrevousYear = null;
					if (selectedYearIndex > 0) {
						const enrichedDataForStateAndPreviousYear =
							enrichedDataByYear[allYearsOfSameType[selectedYearIndex - 1]]
								.find(s => s.state_abbv === state.abbv);
						if (has(enrichedDataForStateAndPreviousYear, metric.name)) {
							const prevousYearValue = enrichedDataForStateAndPreviousYear[metric.name].value;
							deltaFromPrevousYear = prevousYearValue < enrichedDataForStateAndYear[metric.name].value
								?	1
								: prevousYearValue > enrichedDataForStateAndYear[metric.name].value
									? -1
									: 0;
						}
					}

					return {
						metricName: metric.name,
						metricDisplayName: metric.displayName,
						displayValue: enrichedDataForStateAndYear[metric.name].displayValue,
						deltaFromPrevousYear,
					}
				});
			return enrichedIndicators;
		}

		if (!state) return;

		setShareContent({
			text: `Check out ${state.name} in the Elections Performance Index, a nonpartisan, objective measure of U.S. election administration.`,
			subject: `Read about ${state.name} in the Elections Performance Index`
		});

		// get basic info about state
		const stateRank = state ? Object.keys(averageByState).indexOf(state.abbv) + 1 : 0;
		const stateAverage = state ? averageByState[state.abbv] : 0;

		// get basic info about state from other years and put into state
		const allYearsOfTypeDataLocal = [{ 
			date: new Date(selectedYear, 0, 1),
			value: stateAverage,
			year: selectedYear,
			rank: stateRank,
		}];
		availableYearsOfSameTypeAsSelected.forEach(otherYear => {
			const otherYearValue = normalizedAverageByStateByYear[otherYear][state.abbv];
			const otherYearRank = Object.keys(normalizedAverageByStateByYear[otherYear]).indexOf(state.abbv) + 1;
			if (isValidNormalizedValue(otherYearValue)) {
				allYearsOfTypeDataLocal.push({ 
					date: new Date(otherYear, 0, 1),
					value: otherYearValue,
					year: otherYear,
					rank: otherYearRank,
				});
			}
		});
		allYearsOfTypeDataLocal.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
		setAllYearsOfTypeData([...allYearsOfTypeDataLocal]);

		// get additional info for state and put into state
		setStateData({
			rank: stateRank,
			average: stateAverage,
			notableIndicatorNames: getEnrichedFeaturedIndicators(state, enrichedDataByYear, metrics, availableYearsOfSameTypeAsSelected),
			enrichedIndicators: enrichedDataByYear[selectedYear].find(s => s.state_abbv === state.abbv),
		});

		// populate sparkline
		const sparklineContainer = select('.state-profile-modal_sparkline');
		const margin = {top: 10, right: 5, bottom: 5, left: 5.5},
			width = 51 - margin.left - margin.right,
			height = 30 - margin.top - margin.bottom;
			sparklineContainer.selectAll('svg').remove();
			if (allYearsOfTypeDataLocal.length > 1) {
				const color =
					allYearsOfTypeDataLocal[allYearsOfTypeDataLocal.length - 1].value >
					allYearsOfTypeDataLocal[allYearsOfTypeDataLocal.length - 2].value
						? '#37d463'
						: '#f3485a';
			const sparklineSvg = sparklineContainer
				.append('svg')
					.attr('width', width + margin.left + margin.right)
					.attr('height', height + margin.top + margin.bottom)
				.append('g')
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
			const x = scaleTime()
				.domain(extent(allYearsOfTypeDataLocal, function(d) { return d.date; }))
				.range([ 0, width ]);
			const y = scaleLinear()
				.domain([0, max(allYearsOfTypeDataLocal, function(d) { return +d.value; })])
				.range([ height, 0 ]);
			sparklineSvg.selectAll('.lines')
				.data(
					allYearsOfTypeDataLocal
						.slice(0, -1)
						.map((_, i) => ({
							current: { ...allYearsOfTypeDataLocal[i] },
							next: { ...allYearsOfTypeDataLocal[i+1] }
						}))
				)
				.enter()
				.append('line')
				.attr('fill', 'none')
				.attr('stroke', color)
				.attr('stroke-width', 1.5)
				.attr('x1', function(d) { return x(d.current.date);  })
				.attr('y1', function(d) { return y(d.current.value); })
				.attr('x2', function(d) { return x(d.next.date);  })
				.attr('y2', function(d) { return y(d.next.value); });
			sparklineSvg.selectAll('.circle')
				.data(allYearsOfTypeDataLocal)
				.enter()
				.append('circle')
				.attr('r', 1.5)
				.attr('cx', function(d) { return x(d.date);  })
				.attr('cy', function(d) { return y(d.value); })
				.style('fill', '#000000');
		}
	}, [
		state,
		selectedYear,
		averageByState,
		availableYearsOfSameTypeAsSelected,
		normalizedAverageByStateByYear,
		metrics,
		enrichedDataByYear
	]);

	const handleIndicatorComponentResize = (componentName, metric, height) => {
		setIndicatorComponentHeights(prevHeights => ({
			...prevHeights,
			[componentName]: {
				...prevHeights[componentName],
				[metric.groupId]: {
					...prevHeights[componentName][metric.groupId],
					[metric.id]: height,
				}
			},
		}));
	};

  return (
		<div
			className='state-profile-modal full reveal'
			id='state-profile-modal'
			data-reveal=''
			data-v-offset='0'
			data-h-offset='0'
			data-close-on-esc='false'
		>
			<div>
				<div className='state-profile-modal_wrapper'>
					<button data-multiple-opened='true' className='state-profile-modal_close-button close-button' onClick={handleClose} aria-label='Close reveal' type='button'>
						<div className='is-desktop'>
							<span>CLOSE</span>
							<img className='is-not-hovered' alt='close-button' src={closeIcon} />
							<img className='is-hovered' alt='close-button' src={closeHoverIcon} />
						</div>
						<img className='is-mobile' alt='close-button' src={closeMobileIcon} />
					</button>
					<div className='state-profile-modal_header'>
						<div className='state-profile-modal_title-section'>
							<div className='state-profile-modal_title'>
								{state ? state.name : ''}
							</div>
							<span>in</span>
							<button className='button clear' type='button' data-toggle='state-profile-year-selection-dropdown'>
								{selectedYear}
							</button>
							<div
								className='dropdown-pane state-profile-modal_select-year-dropdown'
								id='state-profile-year-selection-dropdown'
								data-dropdown
								data-hover='true'
								data-hover-pane='true'
								data-hover-delay='0'
								data-alignment='center'
							>
								<div className='state-profile-modal_select-year-dropdown-arrow'></div>
								<div className='state-profile-modal_select-year-dropdown-contents'>
									{
										allAvailableYears.map(
											year =>
												<button
													onClick={() => handleSelectedYearChange(year)}
													className={selectedYear === year ? 'clear is-selected' : 'clear'}
													key={year}
												>
													{year}
												</button>
											)
									}
								</div>
							</div>
							<div className='state-profile-modal_epi-average'>
								<div>{state && Math.round(stateData.average*100)}%</div>
								<div className='state-profile-modal_epi-average-description'>
									EPI average (#{stateData.rank})
								</div>
							</div>
							<div className='state-profile-modal_other-years'>
								<div className='state-profile-modal_sparkline'></div>
								<div>
									{ allYearsOfTypeData && 
										[...allYearsOfTypeData]
											.reverse()
											.filter(yearData => yearData.year !== selectedYear)
											.map(yearData =>
												<div key={yearData.year}>
													{yearData.year}: {Math.round(yearData.value*100)}% (#{yearData.rank})
												</div>
											)
									}
								</div>
							</div>
							<div className='state-profile-modal_share'>
								SHARE
								<a target='_blank' rel='noreferrer noopener' href={`https://twitter.com/intent/tweet?url=${currentUrlEncoded}&text=${shareContent.text}`}>
									<img alt='twitter' className='is-not-hover' src={twitterIcon} />
									<img alt='twitter' className='is-hover' src={twitterHoverIcon} />
								</a>
								<a target='_blank' rel='noreferrer noopener' href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrlEncoded}`}>
									<img alt='facebook' className='is-not-hover' src={facebookIcon} />
									<img alt='facebook' className='is-hover' src={facebookHoverIcon} />
								</a>
								<a target='_blank' rel='noreferrer noopener' href={`mailto:?body=${shareContent.text}%0a${currentUrlEncoded}&subject=${shareContent.subject}`}>
									<img alt='email' className='is-not-hover' src={emailIcon} />
									<img alt='email' className='is-hover' src={emailHoverIcon} />
								</a>
								<CopyToClipboard text={currentUrl} >
									<button type='button' className='clear' onClick={handleCopyToClipboard}>
										<img alt='link' className='is-not-hover' src={linkIcon} />
										<img alt='link' className='is-hover' src={linkHoverIcon} />
									</button>
								</CopyToClipboard>
								<span
									className={justCopied ? 'state-profile-modal_share_copy-confirm is-visible' : 'state-profile-modal_share_copy-confirm'}>
									Copied to Clipboard
								</span>
							</div>
						</div>
						<div className='state-profile-modal_header-info'>
							
								{
									stateData.notableIndicatorNames && stateData.notableIndicatorNames.length > 0
										? 
											(
												<div className='state-profile-modal_header-info-section'>
													<div className='state-profile-modal_header-info-section_title'>
														NOTABLE ACTIVITY
													</div>
													<div>
														{
															stateData.notableIndicatorNames.map((metric, index) => (
																<div key={index} className='state-profile-modal_header-info-section_datapoint'>
																	<div>
																		{metric.displayValue}
																		{
																			metric.deltaFromPrevousYear === 1
																				? <img alt='down' src={upArrow} />
																				: metric.deltaFromPrevousYear === -1
																					? <img alt='down' src={downArrow} />
																					: null
																		}
																	</div>
																	<div>
																		{metric.metricDisplayName}
																	</div>
																	
																</div>
															))
														}
													</div>
											</div>
											)
										: null
								}
								{
									state && state.votingConditions && has(state.votingConditions, selectedYear)
										?
											(
												<React.Fragment>
													<div className='state-profile-modal_header-info-section'>
														<div className='state-profile-modal_header-info-section_title'>
															STATE VOTING CONDITIONS
														</div>
														<div>
															<div className='state-profile-modal_header-info-section_condition'>
																<div>
																	Chief Election Official
																</div>
																<div>
																	{get(state.votingConditions[selectedYear],'chiefElectionOfficial')}
																	<a target='_blank' rel='noreferrer noopener' href={state.electionWebsite}>
																		{
																			has(state.votingConditions[selectedYear], 'chiefElectionOfficialTitle')
																				? `(${get(state.votingConditions[selectedYear],'chiefElectionOfficialTitle')})`
																				: null
																		}
																	</a>
																</div>
															</div>
															<div className='state-profile-modal_header-info-section_condition'>
																<div>
																	Mail Voting
																</div>
																<div>
																	{get(state.votingConditions[selectedYear], 'mailVoting')}
																</div>
															</div>
															<div className='state-profile-modal_header-info-section_condition'>
															<div>
																	In-Person Early Voting
																</div>
																<div>
																	{get(state.votingConditions[selectedYear], 'inPersonEarlyVoting')}
																</div>
															</div>
														</div>
													</div>
												</React.Fragment>
											)
										: null
								}
						</div>
					</div>
					<div className='state-profile-modal_indicators'>
						<div className='state-profile-modal_indicators-title'>
							INDICATOR VALUES
						</div>
						<div>
							{
								groupedMetrics.filter(gm => gm.metrics.length > 0).map(gm => (
									<div key={gm.id} className='state-profile-modal_indicators-group'>
										<div className='state-profile-modal_indicators-group-title'>
											{gm.name}
										</div>
										<div className='grid-x grid-padding-x'>
											{
												gm.metrics.map(m => (
													m.yearsAvailable.includes(selectedYear)
														?
															(
																<div
																	key={m.id}
																	className={`cell small-12 medium-${
																		gm.metrics.length <=2 ? 6 : 3
																	} state-profile-indicator`}
																	onClick={() => handleGoToIndicatorProfile(m.id)}
																>
																	<StateProfileIndicator
																		metric={m}
																		stateAbbv={state ? state.abbv : null}
																		enrichedDataForState={stateData.enrichedIndicators && stateData.enrichedIndicators[m.name] ? stateData.enrichedIndicators[m.name] : {}}
																		normalizedDataForAllStates={normalizedDataByStateAndMetric[selectedYear][m.name]}
																		handleComponentResize={handleIndicatorComponentResize}
																		componentHeights={indicatorComponentHeights}
																	/>
																</div>
															)
														:
															(
																<div
																	key={m.id}
																	className={`cell small-12 medium-${
																		gm.metrics.length <=2 ? 6 : 3
																	} state-profile-indicator-not-available`}
																	onClick={() => handleGoToIndicatorProfile(m.id)}
																>
																	<StateProfileIndicatorNotAvailable
																		metric={m}
																		handleComponentResize={handleIndicatorComponentResize}
																		componentHeights={indicatorComponentHeights}
																	/>
																</div>
															)
												))
											}
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
				<div className='state-profile-modal_footer'>
					<button type='button' className='button clear state-profile-modal_footer_select-state' onClick={handleClickViewAnotherState}>
						<span className='is-desktop'>View another state</span>
						<img className='is-mobile' alt='previous' src={previousArrow} />
						<span className='is-mobile'>Select another state</span>
					</button>
				</div>
			</div>
		</div>
  );
}
