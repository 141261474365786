import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedYear, getNormalizedDataByYearByMetric } from '../../selectors';
import { isValidNormalizedValue, MetricTypes, getDisplayValue } from '../../bizUtils';
import IndicatorVsNation from './visualizations/IndicatorVsNation';
import ReactResizeDetector from 'react-resize-detector';

const getVisualForMetric = (
	metric,
	normalizedDataByYear,
	normalizedDataForAllStates,
	selectedYear,
	stateAbbv,
	validValuesForAllStates
) => {
	if (metric.id === 'OLT') {
		const dataSet = normalizedDataByYear[selectedYear][metric.name];
		const allValues = Object.values(dataSet).filter(v => isValidNormalizedValue(v));
		const average = allValues.length ? allValues.reduce((sum, v) => sum + v) / allValues.length : 0;
		const averageDisplayValue = getDisplayValue(null, average, null, true);
		return (
			<div className='state-profile-indicator_relative-to-average'>
				The average state offers {averageDisplayValue} of possible look-up tools
			</div>
		);
	}
	switch (metric.type) {
		case MetricTypes.Numeric:
			const averageAcrossStates =
				validValuesForAllStates.reduce((sum, v) => sum + v, 0)
				/ (validValuesForAllStates.length === 0 ? 1 : validValuesForAllStates.length);
			return (
				<React.Fragment>
					<div className='state-profile-indicator_visual-title'>NATIONWIDE AVERAGE</div>
					<div className='state-profile-indicator_visual'>
						<IndicatorVsNation year={selectedYear} metric={metric} stateAbbv={stateAbbv} />
					</div>
					<div className='state-profile-indicator_relative-to-average is-desktop'>
						{
							isValidNormalizedValue(normalizedDataForAllStates[stateAbbv])
								? normalizedDataForAllStates[stateAbbv] > averageAcrossStates
									? `${stateAbbv} is scoring better than nationwide average`
									: `${stateAbbv} is scoring worse than nationwide average`
								: null
						}
					</div>
				</React.Fragment>
			);
		case MetricTypes.Enum:
			// this logic is specific to AOR, the only enum type currently. Can expand this if more enum types are added
			if (metric.id !== 'AOR') return null;
			return (
				<div className='state-profile-indicator_relative-to-average'>
					{
						Object.keys(metric.values).map((value) => {
							const countAcrossStates = validValuesForAllStates
								.filter(v => v.toString() === value).length;
							if (value === '1')
								return (
									<div key={value}>
										{countAcrossStates} out of 51 states have {metric.displayName}
									</div>
								);
							if (value === '0.5' && countAcrossStates > 0)
								return (
									<div key={value}>
										{countAcrossStates} out of 51 states allow {metric.values[value]}
									</div>
								);
							return null;
						})
					}
				</div>
			);
		case MetricTypes.Boolean:
			const countAcrossStates = validValuesForAllStates.filter(v => v === 1).length;
			return (
				<div className='state-profile-indicator_relative-to-average'>
					{countAcrossStates} out of 51 states have {metric.displayName}
				</div>
			);
		default:
			return null;
	}
}

export default function StateProfileIndicator({
	metric,
	stateAbbv,
	enrichedDataForState,
	normalizedDataForAllStates,
	handleComponentResize,
	componentHeights
}) {
	const validValuesForAllStates = Object.values(normalizedDataForAllStates).filter(v => isValidNormalizedValue(v));
	const selectedYear = useSelector(state => getSelectedYear(state));
	const normalizedDataByYear = useSelector(state => getNormalizedDataByYearByMetric(state));

	const onValueResize = (_width, height) => {
		handleComponentResize('value', metric, height);
	};
	const onNameResize = (_width, height) => {
		handleComponentResize('name', metric, height);
	};
	const onDescriptionResize = (_width, height) => {
		handleComponentResize('description', metric, height);
	};

	// there are resize detectors on the value, name, and description components so that we can keep them
	// aligned horizontally as the page resizes
	return (
		<React.Fragment>
			<div
				style={{ minHeight: Math.max(...Object.values(componentHeights.value[metric.groupId])) }}
				className='state-profile-indicator_value'
			>
				<div>
					<ReactResizeDetector handleHeight onResize={onValueResize} />
					{enrichedDataForState.displayValue}
				</div>
			</div>
			<div
				className='state-profile-indicator_name'
				style={{ minHeight: Math.max(...Object.values(componentHeights.name[metric.groupId])) }}
			>
				<div>
					<ReactResizeDetector handleHeight onResize={onNameResize} />
					{metric.displayName}
				</div>
			</div>
			<div
				className='state-profile-indicator_description'
				style={{ minHeight: Math.max(...Object.values(componentHeights.description[metric.groupId])) }}
			>
				<div>
					<ReactResizeDetector handleHeight onResize={onDescriptionResize} />
					<div dangerouslySetInnerHTML={{__html: metric.text.short_description}}/>
				</div>
			</div>
			{
				getVisualForMetric(
					metric,
					normalizedDataByYear,
					normalizedDataForAllStates,
					selectedYear,
					stateAbbv,
					validValuesForAllStates
				)
			}
		</React.Fragment>
	)
}
